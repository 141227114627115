import React, { useState } from "react";
import { NavLink } from "reactstrap";
var FaqContent = function () {
    var _a = useState(false), buttonClicked = _a[0], setButtonClicked = _a[1];
    return (React.createElement("div", null,
        React.createElement("section", { className: "pt-lg-8" },
            React.createElement("div", { className: "container pt-4 pt-lg-0" },
                React.createElement("div", { className: "row g-4 g-xl-6 align-items-center" },
                    React.createElement("div", { className: "col-md-6" },
                        React.createElement("h1", { className: "mb-4" }, "Frequently Asked Questions"),
                        React.createElement("p", { className: "mb-0" }, "Need a help with something? Here are our most frequently asked questions.")),
                    React.createElement("div", { className: "col-md-6" },
                        React.createElement("svg", { viewBox: "0 0 502.3 300.7", xmlSpace: "preserve" },
                            React.createElement("path", { className: "fill-primary", d: "M170.6,93.6h28.2c1.4,0,2.4,0.9,2.7,2.3l2.2,12.3c0.4,2.2,3.1,3,4.6,1.4l13.4-13.8c1.4-1.4,3.3-2.2,5.2-2.2 h60.8c3.9,0,7.2-3.3,7.2-7.2V17.1c0-3.9-3.3-7.2-7.2-7.2H170.6c-3.9,0-7.2,3.3-7.2,7.2v69.3C163.4,90.3,166.6,93.6,170.6,93.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M209.9,106.6c-0.3,0-0.7,0-0.9-0.1c-1.2-0.4-2-1.4-2.2-2.6l-2.2-12.3c-0.1-1.1-1.1-1.8-2.2-1.8h-28.2 c-4.2,0-7.7-3.5-7.7-7.7V12.8c0-4.2,3.5-7.7,7.7-7.7h117.1c4.2,0,7.7,3.5,7.7,7.7v69.3c0,4.2-3.5,7.7-7.7,7.7h-60.8 c-1.8,0-3.5,0.7-4.7,2l-13.4,13.8C211.7,106.3,210.9,106.6,209.9,106.6z M174.2,6.1c-3.7,0-6.6,3-6.6,6.6v69.3c0,3.7,3,6.6,6.6,6.6 h28.2c1.6,0,2.8,1.1,3.1,2.7l2.2,12.3c0.1,0.8,0.7,1.5,1.5,1.8c0.8,0.3,1.6,0,2.2-0.5l13.4-13.8c1.5-1.5,3.5-2.3,5.6-2.3h60.8 c3.7,0,6.6-3,6.6-6.6V12.8c0-3.7-3-6.6-6.6-6.6h-117V6.1z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M185,24.5c3.9-1.1,8.1-1.6,12.5-1.6c10.7,0,17.4,3,19.8,9c0.5,1.4,0.8,2.8,0.8,4.6c0,4.3-1.2,7.7-3.7,9.9 c-0.9,0.8-2.2,1.8-3.7,2.8c-1.5,1.1-2.6,1.9-3.4,2.6c-1.8,1.6-2.8,3.5-3.5,5.8h-11.7c-0.7-1.9-0.9-3.7-0.9-5.3 c0-1.6,0.3-2.8,0.7-3.9c0.4-0.9,0.9-1.9,1.6-2.6c0.7-0.7,1.4-1.4,2.2-1.8c0.8-0.5,1.5-0.9,2.2-1.4c0.7-0.4,1.2-0.8,1.6-1.4 c0.4-0.4,0.7-0.9,0.7-1.5c0-2.4-1.8-3.7-5.2-3.7c-2.4,0-5.3,0.5-8.5,1.6c-0.7,0.3-1.2,0.4-1.6,0.5V24.5H185z M199.1,59.8 c1.5,0,2.7,0.3,3.8,0.7c1.1,0.4,1.9,0.9,2.6,1.8c1.4,1.4,2,3.3,2,5.4s-0.8,4.2-2.6,6c-1.8,1.8-4.1,2.7-7.1,2.7s-5.2-0.7-6.5-2.2 c-1.4-1.5-2-3.3-2-5.4s0.9-4.2,2.8-6C193.9,60.8,196.2,59.8,199.1,59.8z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M234.6,30.6c-0.1-1.2,0.7-2.2,1.9-2.2c-0.1-0.1-0.3-0.4-0.4-0.5c-0.4,1.1,0.3,2,1.4,2.3 c1.4,0.4,2.6-0.5,3.5-1.5c-0.3-0.1-0.5-0.3-0.7-0.4c-0.1,0.9-0.1,2.7,1.1,3.1c0.5,0.3,1.1,0,1.5-0.4c1.1-0.8,1.9-1.9,2.7-2.8 c-0.3,0-0.5-0.1-0.7-0.1c0.3,1.8,2.3,2.8,3.9,2.2c0.8-0.4,1.5-0.9,2.4-0.8c0.5,0,0.9,0.4,1.5,0.4c1.2,0.1,2.2-0.4,3-1.2 c-0.3,0-0.4,0-0.7-0.1c0.4,0.5,0.7,1.1,1.1,1.8c0.1,0.3,0.4,0.3,0.5,0.1c2.6-1.2,5.3-1.9,8.3-1.9c0.5,0,0.5-0.9,0-0.9 c-3,0-6,0.7-8.7,2c0.1,0,0.4,0.1,0.5,0.1c-0.4-0.5-0.7-1.1-1.1-1.8c-0.1-0.3-0.5-0.3-0.7-0.1c-1.2,1.2-2.4,0.9-3.8,0.4 c-0.5-0.1-0.9-0.3-1.4-0.1c-0.7,0.3-1.1,0.9-1.8,1.1c-1.1,0.3-2.3-0.5-2.4-1.6c0-0.3-0.5-0.4-0.7-0.1c-0.7,0.7-1.2,1.5-2,2.2 c-0.4,0.3-1.2,0.8-1.8,0.4c-0.3-0.1-0.1-1.4-0.1-1.5c0-0.4-0.4-0.8-0.7-0.4c-0.4,0.3-0.7,0.7-1.1,0.9c-0.1,0.1-0.3,0.1-0.5,0.3 c-0.7,0.4-1.4,0-1.8-1.2c0.1-0.3-0.1-0.5-0.4-0.5c-1.6,0-3,1.4-2.7,3.1C233.8,31.3,234.8,31.3,234.6,30.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M230.8,37.8c0.8-0.3,1.5-0.5,2.2-0.9c-0.3-0.1-0.4-0.3-0.7-0.4c0.1,1.6,1.5,3.1,3.3,2.8 c1.1-0.1,1.6-0.9,2.4-1.5c2.2-1.8,2.8,0.1,4.2,1.1c2.2,1.5,3.4-0.7,5.4-0.9c1.8-0.1,2,1.9,3.5,2c0.7,0.1,1.2-0.3,1.8-0.7 c1.4-0.9,2-2.4,3.8-0.9c0.7,0.5,0.9,1.1,1.9,1.1c0.8,0,1.4-0.7,1.9-1.1c0.3-0.3,0.7-0.5,0.9-0.8c0.9,0,1.8,0.3,2.4,0.9 c0.3,0.1,0.4,0.3,0.7,0.4c1.1,0.3,1.8-0.4,2.6-0.9c0.7-0.9,1.6-0.9,2.8-0.3c0.1,0.1,0.3,0.4,0.4,0.5c0.3,0.1,0.4,0.3,0.7,0.3 c0.9,0.3,1.9-0.8,2.6-1.2c1.9-1.4,4.1-1.8,6.2-1.4c0.5,0.1,0.8-0.8,0.3-0.8c-2.7-0.4-4.6,0.3-6.9,1.5c-0.1,0.1-0.4,0.3-0.5,0.4 c-1.1,0.7-1.9,0.7-2.2-0.3c-0.1-0.1-0.3-0.4-0.5-0.4c-0.5-0.3-1.4-0.1-1.9,0.1c-0.8,0.3-1.2,0.9-1.9,1.4c-2.4,1.1-1.5,0-2.6-0.5 c-0.8-0.4-1.2-0.8-2-0.5c-0.9,0.3-1.4,1.2-2.3,1.6c-1.9,0.7-1.9-1.1-3.4-1.5c-2-0.5-2.8,2.3-4.7,2c-1.2-0.1-1.5-1.2-2.6-1.6 c-0.8-0.3-1.4,0-2.2,0.3c-1.9,0.7-2.7,1.6-4.6,0c-0.7-0.5-1.1-1.2-1.9-1.5c-0.7-0.3-1.4,0-1.9,0.4c-0.5,0.3-0.8,0.8-1.2,1.2 c-1.2,1.2-3.7,1.1-3.8-1.1c0-0.3-0.4-0.5-0.7-0.4c-0.7,0.4-1.2,0.7-1.9,0.8C230,37.1,230.3,38,230.8,37.8z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M231.6,52.5c0.3-0.8,0.7-1.5,1.4-2c-0.3,0-0.4,0-0.7-0.1c0.7,1.4,2,3.1,3.8,2.2c0.5-0.3,0.9-0.8,1.2-1.4 c0.5-0.7,0.5-1.2,1.5-1.1s0.8,0.7,1.5,1.1c1.9,1.2,2.4-1.8,4.2-1.4c0.8,0.3,1.2,1.8,1.9,2.2c0.5,0.3,1.1,0.4,1.6,0.3 c0.8-0.3,1.1-0.8,1.8-1.2c1.5-1.1,1.5,0,2.7,0.1c0.9,0.1,1.6-0.9,2.6-1.1c1.1-0.1,2.2,0.8,3.3,0.8c0.8,0.1,1.4-0.1,2-0.7 c1.1-0.9,0.3-1.2,2.8-0.8c0.4,0,0.8,0.3,1.4,0.4c1.5,0.3,3,0.1,4.5,0.1c1.4,0,5.3-0.7,5.8,1.2c0.1,0.3,0.5,0.4,0.7,0.1 c1.2-1.4,3.1-3.4,5.2-1.8c0.4,0.4,1.1-0.3,0.7-0.7c-2.3-1.9-4.7,0-6.4,1.8c0.3,0,0.5,0.1,0.7,0.1c-0.5-1.9-2.6-1.9-4.2-1.9 c-1.9,0-3.7,0.1-5.6,0c-1.4-0.1-2.4-0.7-3.7-0.9c-1.1-0.1-1.4,0.5-2,1.1c-1.8,1.5-2.7,0.3-4.5,0c-0.9-0.1-1.2,0.3-2,0.5 c-1.5,0.5-1.4-0.3-2.4-0.4c-2.3-0.3-2.8,3.8-5.3,0.8c-0.8-1.1-1.8-1.6-3-0.8c-0.7,0.4-1.2,1.6-2.3,0.9c-0.3-0.1-0.5-0.8-0.9-1.1 c-0.4-0.3-0.9-0.5-1.5-0.3c-0.5,0.1-0.9,0.7-1.4,1.2c-0.3,0.4-0.5,0.9-0.9,1.4c-1.4,1.2-2.4-0.3-3-1.5c-0.1-0.3-0.5-0.3-0.7-0.1 c-0.8,0.7-1.4,1.4-1.6,2.4C230.6,52.7,231.5,53,231.6,52.5z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M231.5,63.2c0.3-0.4,2.2-2.7,2.6-2.4c0.5,0.3,0.4,1.2,0.7,1.6c0.4,0.9,1.4,1.4,2.3,0.9c1.5-0.4,2.2-1.9,3.4-2.8 c0.9-0.8,1.8-0.5,2.6,0.3c0.1,0.1,0.3,0.4,0.4,0.5c0.4,0.3,0.8,0.4,1.2,0.4c2-0.1,3-2,5.2-0.3c0.9,0.7,1.8,1.2,2.8,0.8 c0.7-0.3,1.2-0.8,1.9-1.1c1.2-0.7,2.2,0.4,3.4,0.5c0.7,0,1.4-0.1,1.9-0.4c0.7-0.3,4.2-1.8,3.9,0.3c0,0.4,0.3,0.7,0.7,0.5 c0.7-0.4,1.5-0.9,2.2-1.4c0.5-0.3,0-1.1-0.4-0.8c-0.7,0.4-1.5,0.9-2.2,1.4c0.3,0.1,0.4,0.3,0.7,0.5c0.1-0.9-0.3-1.6-1.2-2 c-1.2-0.5-2.3,0-3.4,0.4c-1.2,0.5-2,0.4-3.3,0c-0.3-0.1-0.5-0.3-0.8-0.3c-0.5-0.1-0.9,0-1.4,0.3c-1.1,0.5-2,1.8-3.4,1.2 c-0.7-0.3-1.2-0.9-1.8-1.4c-1.1-0.5-2.2-0.1-3.3,0.3c-0.4,0.1-0.8,0.5-1.2,0.5c-1.9,0-0.9-0.8-1.8-1.2c-0.4-0.3-0.7-0.5-1.2-0.5 c-1.5,0-2.4,1.4-3.4,2.3c-0.3,0.4-0.7,0.7-1.1,0.9c-0.9,0.5-1.8-0.1-2.2-0.9c-0.3-0.7-0.1-0.9-0.7-1.5c-0.1-0.1-0.4-0.1-0.5-0.1 c-1.2,0.8-2.3,1.6-3.3,2.8C230.6,63.1,231.1,63.6,231.5,63.2z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M235,71.5c0.5-2.4,2.4-0.9,3.7-0.3c0.5,0.3,0.5,0.5,1.1,0.5s1.1-0.3,1.6-0.7c1.5-0.9,2.4-1.1,4.1-0.3 c1.4,0.5,2.6,1.2,4.1,0.8c0.8-0.1,1.5-0.5,2.2-0.8c0.7-0.3,1.2-0.5,2-0.5c0.9,0,1.2,0.5,2,1.1c0.9,0.5,1.8,0.5,2.8,0 c1.5-0.7,2.2-1.6,4.1-0.5c0.5,0.4,0.9,0.8,1.5,1.1c1.5,0.7,3-0.3,4.2-0.8c1.8-0.8,6.8-4.1,8.4-2c0.3,0.4,1.1,0,0.8-0.4 c-1.1-1.5-2.7-1.2-4.2-0.7c-1.2,0.4-2.3,1.1-3.5,1.5c-1.5,0.7-3.8,2.3-5.4,1.6c-1.8-0.8-2.2-2.4-4.3-1.5c-1.8,0.7-2.2,1.6-4.5,0.4 c-0.7-0.3-0.9-0.7-1.6-0.7c-2.3-0.1-4.1,2.2-6.5,1.4c-1.9-0.7-3.4-2-5.4-0.9c-0.4,0.3-0.9,0.8-1.5,0.9c-0.7,0.1-1.4-0.5-1.8-0.8 c-0.5-0.3-0.8-0.7-1.5-0.7c-1.5-0.1-2.8,0.8-3.1,2.2C234.1,71.7,234.9,72,235,71.5z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M143.3,277.9l1.9,21.2H36.1c0,0,5-29.3,8.3-31.5C47.8,265.4,143.3,277.9,143.3,277.9z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M59.2,139.1c-3.8-1.2-7.3-3.3-10.3-6c-6.5-5.7-11.1-19.1,0-22.8c2.8-0.9,6-1.1,8.7-2.4c2.7-1.4,4.9-4.5,3.8-7.3 c-0.8-2.2-3.4-3.3-4.7-5.2c-2-2.7-1.5-6.6,0.7-9.2c2.2-2.6,5.6-3.8,9-3.9c2.2-0.1,4.3,0.1,6.4-0.3s4.2-1.8,4.7-3.8 c0.7-2.8-1.8-5.4-2.4-8.3c-0.8-4.5,3.1-8.5,7.5-9.8s9-0.7,13.4-0.9c4.9-0.3,9.6-1.8,14.4-1.6c4.9,0.1,10.2,2.3,11.8,6.9 c6.1-1.9,13.3,0,17.5,4.7c4.3,4.7,5.6,12.1,3.1,17.9c-1.4,3.3-3.7,6.2-3.1,9.6c0.5,3.8,4.5,6.1,6.5,9.4c2.4,4.1,1.5,10-2.2,13.2 c-1.9,1.6-4.3,2.6-6.5,3.9c-2.2,1.4-3.9,3.7-3.7,6.2c0.4,3.1,4.1,5.2,4.5,8.3c0.4,2.7-1.8,5.2-3.7,7.1c-4.2,4.2-9.2,8.5-15.2,8.5 c-5.3,0.1-10-3.4-14.7-6c-6.5-3.7-13.7-6-21.2-6.5c-6.8-0.5-13.8,0.5-20.5-0.8C61.6,139.9,60.4,139.5,59.2,139.1z" }),
                            React.createElement("path", { className: "fill-body", d: "M116.4,111.4c5,3.1,10.3,3,13.7-0.4c3.4-3.4,5.2-12.8,6.8-17.2c2.7-7.5,2.4-14-0.9-21c-0.5-1.1-1.5-0.7-2.6-1.4 c-1.2-0.9-3.7-3.3-5.2-3.7c-9.8-2.6-16.7,5.3-20.6,10.9c-1.9,2.7-1.5,2.6-2,4.2c-0.3,1.1,0.1-6.8-8.3-5.8 c-8.1,0.8-5.6,11.1-4.7,12.6c0.4,0.7,3.1,1.9,5.7,3.5c1.1,0.7,2.7,0.7,3.5,1.5c0.8,0.7,0.8,2.2,0.9,3c1.5,7.9-6,23.6-11,29.9 c-5.2,6.2-10,5.7-18,7.7c3,5.7,9.9,14.4,14.5,19.1c1.9,1.9,3.9,3.8,6.2,5.3c2.8,1.8,6,2.7,9.2,3.4c5.3,0.9,11,0.8,15.7-1.8 c4.6-2.6,7.3-7.5,7.1-12.6c-0.1-1.2-0.7-1.6-1.5-2.3c-2.4-2-4.7-4.1-7.1-6.4c-0.3-0.3-0.5-0.5-0.8-0.8c-1.2-1.2-2.4-2.6-3-4.3 c-0.5-1.4-0.4-3-0.4-4.5c0.3-6.4,0.9-12.9,2.2-19.1C116,110.7,116,111.6,116.4,111.4z" }),
                            React.createElement("path", { className: "fill-primary", d: "M73.6,135.3c-10,1.8-19.3,4.7-27.7,10.4c-6.4,4.3-11.3,16-13.4,22.1c-3.1,8.4-11,22.4-12.3,31.3 c1.9-0.4,19.9-1.8,21.8-1.8c4.7,0.1,9.6,0.1,14.4,0.3c6.1,0.1,12.1,0.8,18,2.6c-2.6,10.4-5.6,21.7-7.1,32.4c4.7-1.6,10.2-2,15.2-1.9 c14.4,0.3,28.8,1.5,43,3.5c5.7-5.3,12.3-9.9,19.7-12.8c-0.1,0.1,0-0.5,0-0.4c1.2-3,2.2-5.2,3.4-8.1c4.9-0.1,9.6,0,14.7-0.1 c-1.2-15.5-2.8-32.4-11.1-45.6c-1.8-2.8-4.7-5.4-7.3-7.5c-5.7-4.3-11.7-8.3-17.9-11.7c0.7,5.7-3.3,10.6-8.3,13.3 c-5,2.7-11,2.8-16.6,1.8c-2.2-0.4-4.3-1.1-6.2-2.2c-2-1.2-3.8-3-5.4-4.6c-2.2-2.2-4.3-4.5-6.5-6.6C82,148,74.4,137.2,73.6,135.3z" }),
                            React.createElement("path", { className: "fill-primary", d: "M133.3,267.6c5.2,0.5,10.3,0.8,15.6,0.4c-0.1,3.8,0,7.2-0.1,11c-13.3,2-26.7,1.2-40.2,0.4 c-3.7-0.3-7.5-0.5-10.4-2.6C110.3,276.3,122.3,273.1,133.3,267.6z" }),
                            React.createElement("path", { className: "fill-body", d: "M73.7,200.4c-1.4,3-1.9,6.4-2.6,9.6c-1.4,7.5-2.8,15.1-4.2,22.5c19.3-3.3,38.8-1.1,58.1,1.4 c4.9-3.9,10.3-7.1,15.6-10.4c1.5-0.9,3.1-1.5,4.7-2c1.6-2.2,3.1-5.6,3.1-8.1c5-0.9,10.2-0.7,15.2,0.1c0.9,0.1,2,0.4,3,0.7 c3.3,1.2,5.6,4.5,5.7,7.9c1.9,0.8,3.3,2.4,4.3,4.2c0.4,0.7,0.8,1.5,0.9,2.2c0.4,1.8-0.1,3.7-0.8,5.3c1.2,1.6,1.5,4.1,0.3,5.8 c-1.1,1.8-3.3,2.6-5.3,1.8c-0.1,1.2-1.2,2.2-2.4,2.4c-1.2,0.3-2.4,0.1-3.7-0.1c-1.4-0.3-2.7-0.3-4.1-0.4c-0.1,3.3-0.3,6.5-0.3,9.8 c-0.1,3.5-0.3,7.3-2.3,10.3c-1.9,2.7-5,4.2-8.1,4.9c-3.3,0.7-6.5,0.4-9.8,0.3c-4.3-0.3-5.6-0.9-9.6,0.3c-4.7,1.5-9.6,3.8-14.5,4.7 c-10.9,2.3-25.4,4.7-36.4,5.8c-11.4,1.1-33.5,0.9-42.5-6.1c-8-6.4-9-13.8-10.2-24c-1.9-15.1-1.1-36,0.7-51 C41.5,197.8,60,196.5,73.7,200.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M108,105.8c4.1,4.2,10.7,8.3,16.7,8.3c6.5,0.1,9.4-8.1,11-13.2c2.4-7.6,4.9-15.9,2.4-23.9 c-2.2-6.9-8.7-12.2-16.1-10.7c-8.4,1.8-15.1,9.1-16.7,17.5c-0.1,0.7,0.8,0.9,0.9,0.3c1.4-6.9,6.2-13,12.8-15.7 c3.7-1.5,7.7-1.8,11.3,0.3c3.1,1.9,5.6,5,6.7,8.4c2.3,7.1,0.4,14.4-1.6,21.2c-0.9,3.1-1.9,6.2-3.4,9.1c-1.2,2.4-3.1,5.3-6.1,5.7 c-3.3,0.5-6.6-0.9-9.5-2.4c-2.8-1.5-5.6-3.3-7.7-5.6C108.3,104.6,107.6,105.3,108,105.8z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M106.3,83.5c-0.3-4.2-4.3-7.6-8.5-7.1c-4.2,0.5-7.3,4.7-7.1,9c0.3,4.6,4.2,7.7,8.4,8.5c3.9,0.7,3.1,4.6,2.4,7.5 c-0.5,2.3-1.2,4.5-1.9,6.6c-1.8,5.6-3.8,11.3-6.5,16.4c-0.3,0.5,0.5,1.1,0.8,0.5c2.6-4.9,4.5-10,6.1-15.2c0.8-2.4,1.5-4.9,2.2-7.5 c0.4-1.9,1.1-4.1,0.7-6.1c-0.4-2.4-2-3-4.2-3.4c-3.3-0.7-6.2-3.1-6.9-6.4c-0.8-3.5,1.4-7.6,4.9-8.7c3.9-1.4,8.3,1.8,8.5,5.8 C105.3,84.2,106.4,84.2,106.3,83.5z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M114.9,111.1c-0.7,7.3-1.4,14.5-2,21.8c0,0.7,0.9,0.7,0.9,0c0.7-7.3,1.4-14.5,2-21.8 C116,110.4,114.9,110.4,114.9,111.1z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M93.9,123.3c-2.7,6-9.5,8.5-15.3,10.2c-6.4,1.6-13,2.4-19.3,4.7c-0.5,0.3-0.4,1.2,0.3,0.9 c6.9-2.6,14.1-3.3,21.2-5.3c5.6-1.6,11.7-4.5,14.1-10C95,123.2,94.2,122.6,93.9,123.3z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M125.3,98.9c-0.4-1.5-1.6-2.7-3.1-3c-0.3,0-0.5,0.1-0.7,0.4c0,0.3,0.1,0.5,0.4,0.7c0.1,0,0.3,0.1,0.5,0.1h0.1 h0.1h0.1c0.1,0.1,0.3,0.1,0.4,0.3c0,0,0,0,0.1,0.1l0.1,0.1c0.1,0.1,0.3,0.3,0.3,0.4c0.1,0.1,0-0.1,0,0c0,0,0,0,0.1,0.1l0.1,0.1 c0,0.1,0.1,0.1,0.1,0.3v0.1c0,0.1,0-0.1,0,0c0,0.1,0.1,0.3,0.1,0.5s0.4,0.4,0.7,0.4C125.1,99.4,125.3,99.2,125.3,98.9z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M120.8,80.7c0.8-0.4,1.6-0.7,2.7-0.9c0.5-0.1,0.8-0.1,1.4-0.1c0.5,0,0.9,0,1.5,0c0.5,0,0.9-0.4,0.9-0.9 c0-0.5-0.4-0.9-0.9-0.9c-2.2-0.1-4.5,0.3-6.5,1.4c-0.4,0.3-0.7,0.9-0.4,1.4C119.8,80.8,120.4,81,120.8,80.7z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M132.9,80.8c0.4,0.3,0.8,0.5,1.2,0.8l-0.1-0.1c0.4,0.4,0.9,0.8,1.2,1.2c0.1,0.1,0.4,0.3,0.7,0.3 c0.3,0,0.5-0.1,0.7-0.3c0.1-0.1,0.3-0.4,0.3-0.7c0-0.1,0-0.1,0-0.3s-0.1-0.3-0.3-0.4c-0.7-0.9-1.6-1.6-2.7-2.2 c-0.3-0.1-0.5-0.1-0.7-0.1c-0.3,0-0.4,0.3-0.5,0.4C132.3,79.9,132.5,80.6,132.9,80.8z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M80.9,213.3c1.1-0.7,2.2-2,2.4-3.1c0.3-1.4,0-3.1-0.8-4.3c-0.7-0.9-1.8-1.6-2.6-2.4c-1.1-1.1-2.3-1.9-3.8-2.4 c-0.5-0.1-1.1-0.4-1.6-0.4c-0.4,0-0.8,0.1-0.9,0.5c-0.3,0.7-0.4,1.4-0.5,2c-0.1,0.8-0.4,1.5-0.5,2.3c-0.4,1.9-0.7,3.7-0.9,5.6 c-0.1,0.7-0.3,1.2-0.3,1.9c-0.1,0.4-0.3,1.1-0.1,1.5c0.3,0.7,1.5,0.7,2.2,0.7c2.2,0.1,4.3-0.3,6.2-1.2 C79.9,213.7,80.5,213.6,80.9,213.3z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M27.6,212.3c-3.1-1.5-6.9-3.4-7.7-7.2c-0.4-1.9,0-4.2,1.6-5.3c1.6-1.1,4.1-0.9,5.8-1.1 c8.4-0.9,16.8-1.2,25.1-0.8c4.1,0.1,8.3,0.5,12.3,1.1c3.7,0.4,7.3,0.9,10.9,2.2c3.5,1.4,8.7,4.7,7.1,9.2c-1.6,4.5-7.3,4.2-11.1,3.8 c-0.7,0-0.7,0.9,0,0.9c3.3,0.3,6.8,0.5,9.6-1.4c2-1.5,3.3-3.8,2.7-6.4c-1.6-6.5-10-8.3-15.6-9.1c-8.4-1.2-17.1-1.8-25.6-1.6 c-4.3,0-8.8,0.3-13.2,0.7c-2.7,0.3-6.9,0-9.1,2c-1.9,1.8-2,4.7-1.1,6.9c1.4,3.4,4.9,5.2,7.9,6.6C27.7,213.6,28.2,212.6,27.6,212.3z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M73.4,200.1c-1.2,6.2-2.7,12.5-3.9,18.7c-1.1,5.2-2.4,10.6-2.8,15.9c0,0.7,0.9,0.7,0.9,0c0.4-5.4,1.9-10.7,3-16 c1.2-6.1,2.6-12.2,3.9-18.3C74.5,199.9,73.6,199.6,73.4,200.1z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M140.2,176c9.9,12.5,11.9,31.1,4.6,45.3c-0.3,0.5,0.5,1.1,0.8,0.5c7.6-14.7,5.6-33.7-4.7-46.5 C140.5,174.7,139.8,175.4,140.2,176z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M163.3,212.6c-4.9-0.5-9.9-0.7-14.8-0.1c-0.7,0-0.7,1.1,0,0.9c4.9-0.4,9.9-0.4,14.8,0.1 C163.9,213.7,163.9,212.7,163.3,212.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M112.9,132.9c0,1.8,0.7,3.3,1.9,4.5c1.4,1.5,2.8,3,4.2,4.3c3,3,6.1,5.6,9.6,7.9c0.5,0.4,1.1-0.5,0.5-0.8 c-3.3-2.2-6.2-4.6-9-7.3c-1.4-1.4-2.6-2.8-4.1-4.1c-1.4-1.2-2.3-2.4-2.3-4.3C114,132.3,112.9,132.3,112.9,132.9z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M73.1,135.3c6.9,11.7,15.1,24.4,28.9,28.2c6,1.6,12.9,1.5,18-2.3c4.3-3.1,7.6-8.4,6.8-14 c-0.1-0.7-1.1-0.4-0.9,0.3c0.9,6-3.5,11.7-8.7,14.2c-5.6,2.7-12.5,1.9-18.2-0.4c-11.8-4.7-18.9-16.1-25.1-26.6 C73.7,134.2,72.7,134.7,73.1,135.3z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M83.7,208.4c1.1-15.2,2-31.1-4.1-45.5c-0.3-0.5-1.1-0.1-0.8,0.5c6.1,14.2,5,30,3.9,45.1 C82.8,208.9,83.7,208.9,83.7,208.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M73.6,154.1c-1.2-3.1-2.8-6.1-4.9-8.7c-0.4-0.5-1.1,0.3-0.7,0.7c1.9,2.6,3.5,5.3,4.6,8.3 C72.9,155.1,73.8,154.8,73.6,154.1z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M98.9,81.8c-1.5,0.4-2.3,2-2.2,3.5c0.1,1.6,1.4,3,2.8,3.8c0.5,0.3,1.1-0.5,0.5-0.8c-1.1-0.7-2-1.6-2.3-2.8 c-0.1-1.1,0.4-2.3,1.5-2.6C99.7,82.6,99.5,81.6,98.9,81.8z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M123.2,87.5c-0.3,1.2-1.2,1.9-2,1.8c-0.8-0.3-1.2-1.4-0.9-2.4c0.3-1.2,1.2-1.9,2-1.8 C123.1,85.3,123.5,86.4,123.2,87.5z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M134.6,90.9c-0.3,1.1-1.1,1.8-1.8,1.5c-0.7-0.1-1.1-1.2-0.8-2.2c0.3-1.1,1.1-1.8,1.8-1.5 C134.6,88.8,134.9,89.9,134.6,90.9z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M90.1,214.6c10,6.4,21.6,10,33.5,10.3c0.7,0,0.7-0.9,0-0.9c-11.7-0.3-23.1-3.8-33-10.2 C90.1,213.4,89.6,214.2,90.1,214.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M67.6,232.4c14.8-3,30.3-1.8,45.2,0.4c15.1,2.2,30,5.7,44.9,9c0.7,0.1,0.9-0.8,0.3-0.9 c-23.6-5.2-47.6-11.1-71.9-11.1c-6.2,0-12.5,0.5-18.7,1.8C66.8,231.6,67,232.6,67.6,232.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M153.2,240.8c2.3,0.4,4.5,0.9,6.8,1.5c1.2,0.3,2.3,0.7,3.4,1.1c1.4,0.4,2.7,1.1,4.2,1.4c2,0.3,3.8-0.9,4.3-3 c0.7-2-0.3-4.1-1.9-5.4c-2.3-2-5.4-2.3-8.4-2.6c-0.7,0-0.7,0.9,0,0.9c3,0.3,6.2,0.5,8.3,2.8c0.8,0.9,1.4,2.3,0.9,3.7 c-0.3,1.1-1.1,2.2-2.3,2.4c-1.5,0.3-3.1-0.7-4.3-1.1c-1.2-0.4-2.4-0.8-3.7-1.2c-2.3-0.7-4.7-1.2-7.1-1.6 C152.8,239.7,152.5,240.7,153.2,240.8z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M155.9,231.3c4.9-2,10.6-2,15.3,0c2.3,0.9,5,2.6,5.7,5.2c0.4,1.2,0.3,2.7-0.7,3.8c-0.9,0.9-2.3,1.1-3.4,0.5 c-0.5-0.3-1.1,0.5-0.5,0.8c2.2,1.4,4.9-0.1,5.6-2.4c0.9-3-1.2-5.8-3.7-7.5c-5.6-3.7-12.9-3.9-19-1.4 C154.8,230.8,155.3,231.6,155.9,231.3z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M151.7,226.6c2-2.2,4.7-3.4,7.6-4.2c3.1-0.9,6.5-1.8,9.8-1.1c2.7,0.5,5.2,2,6.9,4.3c1.6,2,1.9,5.3,0,7.5 c-0.4,0.5,0.3,1.2,0.7,0.7c1.6-1.9,2-4.9,0.9-7.2c-1.2-2.7-4.1-4.9-6.9-5.8c-3.7-1.2-7.5-0.5-11.1,0.4c-3.3,0.9-6.4,2.3-8.8,4.7 C150.5,226.3,151.2,227,151.7,226.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M145.5,221.8c4.3-2.8,9-6,14.1-7.2c2.4-0.5,5.6-0.8,7.9,0.1c2.3,0.9,4.1,3.5,4.1,6.1c0,0.7,0.9,0.7,0.9,0 c0-2.4-1.4-4.7-3.3-6.1c-2-1.6-5-1.6-7.6-1.5c-6.1,0.5-11.7,4.3-16.7,7.6C144.4,221.4,144.9,222.2,145.5,221.8z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M144.9,221c-3.1,1.8-6.2,3.4-9.2,5.3c-3.7,2.2-7.6,4.5-10.4,7.6c-0.4,0.5,0.3,1.2,0.7,0.7 c3-3.1,6.9-5.4,10.4-7.6c3-1.8,6.1-3.4,9.1-5.2C146,221.6,145.5,220.7,144.9,221z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M66.5,234.7c-0.3,5.2,0.9,9.9,5.2,13.3c3.3,2.6,7.2,3.9,11,5.4c10.3,3.9,20.6,7.6,31.2,10.6 c6.1,1.8,12.3,3.4,18.7,4.1c0.7,0.1,0.7-0.9,0-0.9c-11.1-1.2-22.1-4.9-32.7-8.5c-4.7-1.6-9.4-3.3-14-5c-3.1-1.2-6.4-2.2-9.4-3.7 c-3-1.5-5.7-3.4-7.3-6.2c-1.5-2.7-1.8-5.8-1.6-8.8C67.6,234.2,66.5,234.2,66.5,234.7z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M28,198.4c-0.7,3.5-0.7,7.2-0.8,10.7c-0.1,5.8-0.3,11.7-0.3,17.4c0,10.9-0.1,22.1,1.8,32.8 c2,11.4,13.2,18.3,23.7,20.2c9.8,1.8,19.9,1.5,29.7,0.4c17.6-1.8,35.7-4.9,52.2-11.7c0.5-0.3,0.3-1.2-0.3-0.9 c-14.5,5.8-30.4,9-45.9,10.9c-10.2,1.2-20.2,1.8-30.4,0.9c-5.8-0.5-11.7-1.8-16.7-4.7c-4.9-3-9.8-8-11.1-13.7 c-0.9-4.1-1.2-8.4-1.4-12.6c-0.4-5.8-0.5-11.7-0.5-17.5c-0.1-6.5,0-13,0.1-19.5c0.1-4.1,0.1-8.4,0.8-12.3 C29,198,28.1,197.7,28,198.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M161.9,242.7c-0.3,3.5-0.1,7.1-0.5,10.6c-0.4,4.5-1.6,9.8-5.8,12.2c-3.5,2.2-8.5,2-12.6,2.2 c-3.5,0.1-6.9-0.3-10.3-0.5c-0.7,0-0.7,0.9,0,0.9c3.8,0.3,7.6,0.7,11.4,0.5c4.2-0.1,9.2-0.1,12.8-2.7c7.1-5.2,5.6-15.6,6.1-23.2 C162.9,242.1,161.9,242.1,161.9,242.7z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M27.7,213.1l0.7-14.7c-1.5,0-0.5,0-2,0c-1.6,0-3.3,0-4.7,0.8c-1.9,0.9-3,3.4-2.6,5.4s2,3.5,3.7,4.7 C24.4,210.7,26.3,211.7,27.7,213.1z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M169.8,237c-0.9,0.7-1.5,1.8-1.4,2.8c0.1,1.1,0.9,2,1.9,2.4c0.3,0.1,0.5-0.1,0.7-0.4c0.1-0.3-0.1-0.5-0.4-0.7 c-0.7-0.3-1.1-0.8-1.2-1.5c-0.1-0.7,0.3-1.5,0.8-1.9c0.3-0.1,0.3-0.4,0.1-0.7C170.3,237,169.9,236.9,169.8,237z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M175.3,233.5c-1.2,0.7-1.8,2-1.5,3.4s1.5,2.4,2.7,2.7c0.7,0.1,0.9-0.8,0.3-0.9c-0.9-0.3-1.8-0.9-2-2 c-0.1-0.8,0.3-1.9,0.9-2.3C176.4,234,175.9,233.2,175.3,233.5z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M174.3,224.1c-0.9,1.2-1.6,2.7-0.9,4.2c0.3,0.5,0.7,1.2,1.4,1.5c0.3,0.1,0.7,0.3,1.1,0.3c0.3,0,0.7,0,0.8,0.3 c0.3,0.1,0.5,0,0.7-0.1c0.1-0.3,0-0.5-0.1-0.7c-0.5-0.4-1.1-0.3-1.8-0.4c-0.4-0.1-0.8-0.5-1.1-0.9c-0.5-1.1,0-2.3,0.7-3.1 c0.1-0.3,0.3-0.5,0-0.7C174.8,223.9,174.5,223.9,174.3,224.1z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M166.2,214.4c-0.4,1.2-0.1,2.6,0.7,3.4c0.8,0.9,2.2,1.2,3.3,0.9c0.3,0,0.4-0.4,0.4-0.7 c-0.1-0.3-0.4-0.4-0.7-0.4c-0.8,0.1-1.8-0.1-2.3-0.8c-0.3-0.3-0.4-0.7-0.5-1.2v-0.1v-0.1c0-0.1,0-0.3,0-0.4v-0.1v-0.1 c0-0.1,0.1-0.3,0.1-0.4c0.1-0.3-0.1-0.5-0.4-0.7C166.5,214,166.2,214.1,166.2,214.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M107.9,105.4c0,0,0.5,7.3,6.4,14.2l0.8-9.1L107.9,105.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M128.1,89.5c-0.5,0.7-0.7,1.8-0.4,2.6c0.1,0.5,0.4,0.8,0.8,1.2c0.3,0.3,0.7,0.7,0.5,1.1c-0.1,0.7-1.5,0.1-2,0.3 c-0.8,0-1.6,0.5-1.6,1.4c0,0.7,1.1,0.7,0.9,0c0-0.5,1.2-0.4,1.5-0.3c0.8,0.1,1.9,0,2-1.1c0.1-0.4,0-0.9-0.3-1.4 c-0.3-0.4-0.5-0.8-0.8-1.2c-0.4-0.7-0.4-1.6,0.1-2.3c0.1-0.3,0-0.5-0.1-0.7C128.7,89.1,128.4,89.2,128.1,89.5z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M122.4,100.2c-0.3-0.5-0.7-1.1-1.1-1.5c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0.1 c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.3,0.1,0.4,0.4,0.5,0.7c0,0,0-0.1-0.1-0.1c0.1,0.3,0.3,0.4,0.4,0.7 c0,0.1,0.1,0.1,0.3,0.3c0.1,0,0.3,0,0.4,0C122.5,100.8,122.5,100.5,122.4,100.2z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M450.5,299.9H0.8c-0.4,0-0.8-0.4-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8h449.7c0.4,0,0.8,0.4,0.8,0.8 C451.4,299.5,451,299.9,450.5,299.9z" }),
                            React.createElement("path", { className: "fill-primary", d: "M393.7,88.4c-2.3-6.2-7.1-10.9-12.9-14.1c-3.3,1.2-6.4,2.4-9.6,3.7c2.4,2.4,5.2,4.6,8.1,6.4 c-5,2.4-9.6,5.6-14,9.1c0.5,4.1,1.6,8,3.1,11.8c-4.3,1.1-8.4,2.8-12.2,5.3c-0.5,3.9-0.9,7.3-2.6,11c-0.1,0.4-0.3,0.7-0.4,1.1 c1.8-4.7-3.1-10.7,0-14.8c1.6-2,5.2-3,5-5.6c0-0.7-0.3-1.4-0.5-1.9c-1.4-2.7-2.6-5.4-3.9-8.1c3.7-2.6,7.5-4.9,11.3-7.2 c-3-5.2-5.8-10.3-8.4-15.7c-6.1,0.3-12.1,1.8-17.1,5.2c-6.6,4.3-11.3,12.1-10.4,19.9c0.5,5.3,3.5,10,6.5,14.5c3,4.5,6.1,9,7.2,14.1 c1.1,5.2-0.3,11.4-4.9,14.2c1.6,2.3,5.4,3.8,8.3,4.1c2.8,0.3,5.3-0.4,8,0.3c1.8-7.7,6.9-14.7,13.8-18.5c5.2-2.8,11.1-4.1,16-7.3 C393.3,110,397.2,98.2,393.7,88.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M342,133.2c-0.9-0.1-1.8-0.1-2.6,0c-1.8,0.3-3.1,2.3-4.1,3.7c-1.4,2.2-2.3,4.6-1.8,7.1c0.7,2.6,3.1,4.3,5.7,5.3 s5.3,1.1,7.9,1.6c1.2,0.3,2.4,0.7,3.7,0.4c1.6-0.3,2.8-1.6,3.8-3c1.4-1.8,2.4-3.8,3.3-5.7c0.1-0.4,0.3-0.7,0.3-1.1 c0-0.5-0.4-0.9-0.8-1.2c-3.3-2.8-7.2-4.9-11.4-6C345,133.9,343.5,133.5,342,133.2z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M347.1,132.1c1.6-6.2,2.7-12.8,3-19.1c0.4-6.1,0.5-13-0.9-19c-0.5-1.9-1.4-4.1-3.3-5c-1.2-0.7-2.7-0.5-3.4,0.8 c-1.6,3.3,1.5,7.3,4.7,7.9c2,0.3,3.9-0.7,5.7-1.6c1.1-0.7,2.3-1.4,3.5-1.8c0.7-0.1,1.8-0.4,2.4-0.1c1.1,0.5,1.1,1.6,1.4,2.6 c0.9,4.2,4.2,6,8,3.9c2.4-1.2,4.9-3.4,7.7-2.6c3.7,0.9,5,5,3.9,8.4c-0.5,1.8-2.2,4.2-4.3,3.9c-2-0.3-2.4-3.4-1.8-4.9 c0.9-2.2,3.4-2.7,5.2-1.2c1.8,1.4,2,3.7,0.9,5.7c-3.4,6.6-12.9,4.1-18.5,7.5c-2.3,1.4-3.8,3.7-5,6c-1.8,3.3-3.3,6.6-4.7,10 c-0.1,0.4,0.4,0.8,0.7,0.4c1.5-3.3,3-6.5,4.6-9.8c1.6-3.1,3.8-5.8,7.2-7.1c5.2-1.9,12.1-0.7,15.7-5.6c1.2-1.6,1.9-3.9,0.9-5.8 c-0.8-1.6-2.6-3-4.5-2.8c-3.8,0.4-5,5.8-1.9,8c3.9,2.7,6.8-4.3,6.6-7.2c-0.3-4.5-4.7-7.5-8.8-5.8c-2.3,0.9-4.9,3.5-7.6,3.1 c-2.2-0.3-3.1-2.2-3.7-4.1c-0.3-0.8-0.1-2-0.8-2.7c-1.2-1.4-3.7-0.7-5.2,0c-2.7,1.2-5.6,3.8-8.7,2.6c-1.9-0.7-3.9-3.1-3.5-5.3 c0-0.1,0-0.3,0-0.5c0.8-0.4,1.8-0.8,2.6-1.4c0.7,0.1,1.4,1.2,1.8,1.8c0.5,0.8,0.8,1.6,1.1,2.6c1.1,3.8,1.1,8.3,1.1,12.2 c0.1,8.7-0.9,17.4-3.1,25.9C346.3,132.4,347,132.7,347.1,132.1z" }),
                            React.createElement("path", { className: "fill-body", d: "M340.1,134.3c1.2,0.9,2,2.2,3,3.4c0.9,1.2,1.9,2.2,3.1,3.1c2.6,2.2,5.3,4.1,7.9,6.2c0.4,0.3,0.9-0.3,0.5-0.5 c-2.7-2-5.6-4.1-8.1-6.4c-1.2-1.1-2.3-2.2-3.3-3.4c-0.8-1.1-1.6-2.3-2.8-3.1C340.1,133.4,339.7,134,340.1,134.3z" }),
                            React.createElement("path", { className: "fill-body", d: "M338,137.4c3.3,4.3,6.9,8.1,11.1,11.5c0.4,0.3,0.9-0.3,0.5-0.5c-4.2-3.3-7.9-7.2-11-11.4 C338.3,136.6,337.8,137,338,137.4z" }),
                            React.createElement("path", { className: "fill-body", d: "M337.4,142.3c1.2,1.6,2.8,3.1,4.6,4.2c0.4,0.3,0.8-0.4,0.4-0.7c-1.8-1.1-3.3-2.4-4.5-4.1 c-0.1-0.1-0.4-0.1-0.5,0S337.2,142,337.4,142.3z" }),
                            React.createElement("path", { className: "fill-body", d: "M388.9,85.7c1.9,2.6,2.7,5.7,2.2,8.8c-0.1,0.4,0.7,0.7,0.7,0.1c0.5-3.3-0.3-6.6-2.3-9.4 C389.3,85,388.7,85.3,388.9,85.7z" }),
                            React.createElement("path", { className: "fill-body", d: "M387.8,89.4c0.7,1.6,1.1,3.5,0.9,5.3c0,0.4,0.7,0.4,0.7,0c0.1-1.9-0.3-3.8-0.9-5.4c-0.1-0.1-0.3-0.3-0.4-0.3 C388,89,387.8,89.2,387.8,89.4z" }),
                            React.createElement("path", { className: "fill-body", d: "M335.6,96.3c-0.4-6.2,2.4-12.5,7.3-16.4c0.4-0.3-0.1-0.8-0.5-0.5c-5,3.9-8,10.4-7.6,16.8 C334.9,96.7,335.6,96.7,335.6,96.3z" }),
                            React.createElement("path", { className: "fill-body", d: "M334.1,89.5c0.3-2.2,1.1-4.2,2.4-5.8c0.3-0.4-0.3-0.8-0.5-0.5c-1.5,1.9-2.4,4.1-2.6,6.4 C333.4,90.1,334.1,90.1,334.1,89.5z" }),
                            React.createElement("path", { className: "fill-primary", d: "M10,268.9c3.1,0,5.7-2.6,5.7-5.7c0-3.1-2.6-5.7-5.7-5.7c-3.1,0-5.7,2.6-5.7,5.7C4.3,266.4,6.9,268.9,10,268.9z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M9.6,263.8c-1.8,0-3.3-1.6-3.3-3.7c0-2,1.5-3.7,3.3-3.7c1.8,0,3.3,1.6,3.3,3.7C12.8,262.1,11.4,263.8,9.6,263.8 z M9.6,257.3c-1.4,0-2.4,1.2-2.4,2.8c0,1.5,1.1,2.8,2.4,2.8c1.4,0,2.4-1.2,2.4-2.8C12.1,258.6,11,257.3,9.6,257.3z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M172.3,149.5l-9.9-9.9l9.9-9.9l9.9,9.9L172.3,149.5z M163.5,139.7l8.7,8.7l8.7-8.7l-8.7-8.7L163.5,139.7z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M183.7,132.1c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.4,0-2.4,1.1-2.4,2.4 C181.3,131,182.4,132.1,183.7,132.1z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M201.8,146.4c0,2.6-2,4.6-4.6,4.6c-2.6,0-4.6-2-4.6-4.6c0-2.6,2-4.6,4.6-4.6 C199.8,141.8,201.8,143.8,201.8,146.4z" }),
                            React.createElement("path", { className: "fill-primary", d: "M412.6,141.1c0,3.8-3.4,6.9-7.7,6.9c-4.3,0-7.7-3.1-7.7-6.9c0-3.8,3.4-6.9,7.7-6.9 C409.2,134.2,412.6,137.3,412.6,141.1z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M384.1,159.5c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6s6,2.7,6,6C389.9,157,387.3,159.5,384.1,159.5z M384.1,148.6 c-2.8,0-5,2.3-5,5c0,2.8,2.3,5,5,5c2.8,0,5-2.3,5-5C389.1,150.9,386.8,148.6,384.1,148.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M403.5,144.6L393,134.2l10.4-10.4l10.4,10.4L403.5,144.6z M394.2,134.2l9.2,9.2l9.2-9.2l-9.2-9.2L394.2,134.2z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M407,160.8c0,1.6-1.4,3-3,3c-1.6,0-3-1.4-3-3c0-1.6,1.4-3,3-3C405.6,157.8,407,159.1,407,160.8z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M289.9,263.4c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6s6,2.7,6,6C295.7,260.8,293.1,263.4,289.9,263.4z M289.9,252.4 c-2.8,0-5,2.3-5,5c0,2.8,2.3,5,5,5c2.8,0,5-2.3,5-5S292.6,252.4,289.9,252.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M312.8,264.6c0,1.6-1.4,3-3,3s-3-1.4-3-3s1.4-3,3-3S312.8,263,312.8,264.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M289.7,277.3c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5c-2.5,0-4.5,2-4.5,4.5S287.3,277.3,289.7,277.3z" }),
                            React.createElement("path", { className: "fill-body", d: "M323.7,245.6c-3.9-0.5-3.8,0.5-8.3-0.9c-11.5-3.8-24.2-6.4-35.6-2.4c-4.2,1.5-7.9,3.7-11.8,5.7 c-9.8,5-20.2,8.3-30.7,11.4c-3.8,1.2-7.7,2.3-11.7,2.3c-3.9,0-8.1-1.4-10.6-4.6c-3.7-4.7-2.2-11.9,1.4-17c3.1-4.3,7.6-7.6,12.5-9.2 c3.7-1.2,7.6-1.6,11.3-3c3.7-1.4,7.1-4.2,7.7-8c0.7-4.5-2.8-8.7-6.6-11.1c-4.3-2.8-11.4-3.8-16-6.2c-12.9-6.8-21-8.3-23.7-14 c-2.3-5,0.5-14.9,5.3-17.1c0.8-0.4,1.8-0.8,2.2-1.5c0.4-0.8,0.1-1.9,0-2.7c-1.5-7.3,3.3-15.3,10.3-17.8c-1.8-8,2.4-16.8,9.8-20.5 c-1.1-4.2,1.4-8.7,4.9-11.1c8.3-5.8,22.3-1.9,30,0.9c12.5,4.3,25.2,9.9,34.9,18.9c9.8,9,21.8,19.8,37,28.8c6.2,3.7,9.4,4.7,17.2,9.6 L323.7,245.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M225.7,262.4c-4.7,0-8.8-1.8-11.3-4.9c-4.1-5.3-2.2-12.9,1.4-17.6c3.1-4.3,7.7-7.7,12.9-9.5 c1.8-0.5,3.5-0.9,5.3-1.4c2-0.4,4.1-0.8,6-1.6c4.1-1.5,6.8-4.3,7.3-7.5c0.8-5.3-4.7-9.4-6.4-10.6c-2.3-1.5-5.6-2.6-8.7-3.5 c-2.6-0.8-5.2-1.6-7.3-2.7c-4.1-2.2-7.7-3.8-10.9-5.2c-6.8-3-11.3-5-13.2-9.1c-2.4-5.3,0.7-15.6,5.6-18l0.3-0.1 c0.7-0.3,1.4-0.7,1.6-1.1c0.3-0.5,0.1-1.4,0-2.2v-0.3c-1.5-7.5,3.1-15.6,10.2-18.3c-1.5-7.9,2.7-16.6,9.8-20.5 c-0.9-4.6,1.9-9,5.3-11.4c6.2-4.5,16.6-4.2,30.7,0.8c11.7,4.1,25,9.6,35.1,19c10.6,9.8,22.1,19.9,36.9,28.6c2.6,1.5,4.6,2.6,6.8,3.8 c2.8,1.5,5.8,3.1,10.4,5.8l0.4,0.3l-29.6,70.6h-0.5c-1.5-0.1-2.3-0.1-3.1-0.1c-1.2,0-2.4,0.1-5.3-0.8c-10-3.4-23.2-6.6-35.1-2.4 c-3,1.1-6,2.6-8.8,4.1c-0.9,0.5-2,1.1-3,1.6c-9.8,5-20.5,8.3-30.8,11.5C233.7,261.2,229.8,262.4,225.7,262.4z M244.7,115.4 c-4.5,0-8,0.9-10.7,2.8c-3,2.2-5.7,6.4-4.6,10.4l0.1,0.5l-0.4,0.3c-7.1,3.5-11,12.1-9.4,19.8l0.1,0.5l-0.5,0.1 c-6.8,2.3-11.3,10-9.9,17.1v0.3c0.1,0.9,0.4,2-0.1,3c-0.4,0.9-1.4,1.4-2.2,1.6l-0.3,0.1c-4.3,2.2-7.2,11.5-5,16.3 c1.8,3.7,6,5.6,12.5,8.4c3.3,1.4,6.8,3,11,5.2c2,1.1,4.5,1.8,7.2,2.6c3.3,0.9,6.5,2,9,3.7c5,3.3,7.6,7.6,6.9,11.8 c-0.5,3.7-3.5,6.8-8.1,8.5c-2,0.8-4.1,1.2-6.2,1.6c-1.6,0.4-3.4,0.7-5.2,1.4c-4.9,1.6-9.2,4.7-12.2,9c-3.3,4.5-5,11.4-1.4,16.1 c2,2.7,5.8,4.3,10.2,4.3c3.9,0,7.7-1.2,11.5-2.3c10.3-3.1,20.9-6.4,30.7-11.4c0.9-0.5,2-1.1,3-1.5c2.8-1.5,5.8-3.1,9-4.2 c12.3-4.3,25.6-0.9,36,2.4c2.7,0.9,3.7,0.8,4.9,0.8c0.7,0,1.6-0.1,2.8,0l28.8-68.7c-4.2-2.6-7.2-4.1-9.9-5.6 c-2.2-1.1-4.2-2.2-6.9-3.8c-14.9-8.8-27-19.5-37-28.9c-10-9.2-23.2-14.7-34.7-18.7C256.1,116.8,249.8,115.4,244.7,115.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M222,135c0.1,0.1,0.4,0,0.4-0.1c1.9-2.6,3.7-4.7,6.2-5.4c2.4-0.7,4.9,0,7.7,0.9l12.5,4.1c0.1,0,0.4,0,0.4-0.3 c0-0.1,0-0.4-0.3-0.4l-12.5-4.1c-2.4-0.8-5.3-1.8-8.1-0.9c-3,0.9-5.2,3.7-6.6,5.7C221.7,134.6,221.7,134.8,222,135z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M222.2,135.3c-0.1,0-0.1,0-0.3,0h-0.1c-0.1-0.1-0.3-0.3-0.3-0.4s0-0.3,0.1-0.4c1.5-2,3.7-4.9,6.8-5.8 c2.8-0.8,5.8,0.1,8.3,0.9l12.5,4.1c0.1,0,0.3,0.1,0.3,0.3c0.1,0.1,0.1,0.3,0,0.4c-0.1,0.3-0.4,0.4-0.7,0.4l-12.5-4.1 c-2.7-0.9-5.3-1.6-7.6-0.9c-2.4,0.7-4.2,2.8-6.1,5.4C222.6,135.1,222.3,135.3,222.2,135.3z M230.7,128.6c-0.7,0-1.5,0.1-2.2,0.3 c-3,0.8-5,3.7-6.5,5.7v0.1v0.1c0.1,0,0.1,0,0.1,0c1.9-2.6,3.7-4.7,6.4-5.6c2.4-0.7,5,0,7.9,0.9l12.5,4.1c0.1,0,0.1,0,0.3-0.1V134 c0,0,0,0-0.1-0.1l-12.5-4.1C234.9,129.3,232.9,128.6,230.7,128.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M211.8,154.7c0.1,0,0.3,0,0.4-0.1c2.3-2.4,4.6-4.5,7.6-4.7c1.6-0.1,3.1,0.3,4.6,0.7c5.4,1.6,11.1,3.3,16.1,6.1 c0.1,0.1,0.4,0,0.5-0.1c0.1-0.1,0-0.4-0.1-0.5c-5-3-10.9-4.6-16.3-6.2c-1.5-0.4-3.3-0.9-4.9-0.8c-3.1,0.3-5.6,2.6-8,5 C211.6,154.3,211.6,154.5,211.8,154.7C211.7,154.7,211.7,154.7,211.8,154.7z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M240.7,157c-0.1,0-0.1,0-0.3-0.1c-5-2.8-10.6-4.5-16.1-6.1c-1.5-0.4-3-0.8-4.6-0.7c-2.8,0.3-5.2,2.4-7.5,4.7 c-0.1,0.1-0.4,0.3-0.7,0.1c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.3,0.1-0.4c2.4-2.6,4.9-4.7,8.1-5 c1.8-0.1,3.5,0.4,5,0.8c5.6,1.6,11.3,3.3,16.4,6.2c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3,0,0.4C241.2,156.8,241,157,240.7,157z  M220.4,149.6c1.4,0,2.7,0.4,4.1,0.8c5.4,1.6,11.1,3.3,16.1,6.1c0.1,0,0.1,0,0.3,0v-0.1c0,0,0-0.1-0.1-0.1c-5-3-10.7-4.6-16.3-6.1 c-1.5-0.4-3.1-0.9-4.9-0.8c-3.1,0.3-5.6,2.4-7.9,4.9v0.1v0.1h0.1c2.3-2.4,4.7-4.6,7.7-4.9C220,149.6,220.1,149.6,220.4,149.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M204.6,173.5c0.1,0.1,0.4,0,0.5-0.1c0.8-1.4,2.4-1.9,3.7-1.9c1.5,0,2.8,0.4,4.5,0.9c5.2,1.8,10.4,3.9,15.3,6.2 c0.1,0.1,0.4,0,0.5-0.1c0.1-0.1,0-0.4-0.1-0.5c-5-2.4-10.2-4.5-15.5-6.4c-1.6-0.5-3.1-1.1-4.7-0.9c-1.9,0-3.5,0.9-4.3,2.3 C204.4,173.1,204.5,173.4,204.6,173.5z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M228.9,179h-0.1c-5-2.4-10.2-4.5-15.3-6.2c-1.5-0.5-3-0.9-4.3-0.9c-1.2,0-2.8,0.5-3.5,1.8 c-0.1,0.3-0.5,0.4-0.8,0.3c-0.3-0.1-0.4-0.5-0.3-0.8c0.8-1.4,2.6-2.3,4.5-2.3c1.6,0,3.3,0.4,4.7,0.9c5.3,1.8,10.4,3.9,15.5,6.4 c0.3,0.1,0.4,0.5,0.3,0.8C229.3,178.8,229.1,179,228.9,179z M228.8,178.5c0.1,0,0.3,0,0.3,0c0-0.1,0-0.1-0.1-0.3 c-5-2.4-10.2-4.5-15.5-6.4c-1.6-0.5-3.1-1.1-4.6-0.9c-1.8,0-3.4,0.8-4.2,2.2c0,0.1,0,0.1,0,0.3c0.1,0,0.1,0,0.3,0 c0.8-1.4,2.6-1.9,3.9-2c1.5,0,3,0.4,4.6,0.9C218.6,173.9,223.8,176.1,228.8,178.5z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M235.3,197.3c0,0,0.1,0,0.3,0c0.3,0,0.4-0.3,0.3-0.4c-0.5-3.7,0.3-7.6,2.3-10.9c0.5-0.9,1.4-1.8,2-2.7 c1.2-1.5,2.4-3.1,3.1-5c0.8-2,0.8-4.3,0.8-6.5c0-1.8,0-3.7,0.5-5.4c0.9-3.4,3.4-6,5.8-8.5c1.9-1.9,3.8-3.9,5-6.2 c1.1-2,1.8-4.2,2.3-6.4c0.3-1.1,0.7-2.2,0.9-3.3c0.9-2.7,2.7-6.1,6-7.5c0.1-0.1,0.3-0.3,0.3-0.5c-0.1-0.1-0.3-0.3-0.5-0.3 c-2.7,1.1-4.9,3.8-6.4,7.9c-0.4,1.1-0.7,2.2-0.9,3.3c-0.5,2-1.2,4.2-2.3,6.1c-1.2,2.3-3.1,4.2-4.9,6.1c-2.4,2.6-5.2,5.3-6.1,9 c-0.5,1.8-0.5,3.8-0.5,5.6c0,2.2,0,4.2-0.8,6.2c-0.7,1.8-1.8,3.3-3,4.7c-0.7,0.8-1.4,1.8-2,2.7c-2.2,3.3-3,7.5-2.4,11.4 C235.2,197.1,235.2,197.1,235.3,197.3z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M235.5,197.5c-0.1,0-0.1,0-0.3,0c-0.1-0.1-0.3-0.3-0.3-0.4c-0.5-3.9,0.3-8.1,2.4-11.5c0.7-0.9,1.4-1.9,2-2.7 c1.2-1.5,2.3-3,3-4.7c0.7-1.9,0.8-3.9,0.8-6.1c0-1.9,0-3.8,0.5-5.7c0.9-3.7,3.5-6.4,6.1-9c1.8-1.9,3.7-3.8,4.9-6.1 c1.1-1.9,1.6-4.1,2.3-6.1V145c0.3-1.1,0.7-2.2,0.9-3.3c1.5-4.1,3.7-6.8,6.5-8c0.1,0,0.3,0,0.4,0s0.3,0.1,0.3,0.3 c0.1,0.3,0,0.7-0.3,0.8c-3.1,1.2-4.9,4.6-5.8,7.3c-0.4,1.1-0.7,2.2-0.9,3.3c-0.7,2.2-1.2,4.3-2.3,6.4c-1.4,2.4-3.3,4.3-5,6.4 c-2.6,2.7-4.9,5.2-5.8,8.5c-0.4,1.8-0.5,3.5-0.5,5.3c0,2.2,0,4.5-0.8,6.5c-0.7,1.9-2,3.5-3.1,5c-0.7,0.8-1.4,1.8-1.9,2.7 c-2,3.1-2.8,7.1-2.3,10.7C236.1,197.1,235.9,197.4,235.5,197.5C235.6,197.5,235.6,197.5,235.5,197.5z M264.8,134.2 C264.6,134.2,264.6,134.2,264.8,134.2c-2.7,1.1-4.9,3.8-6.2,7.7c-0.4,1.1-0.7,2.2-0.9,3.3v0.1c-0.5,2.2-1.2,4.3-2.3,6.2 c-1.2,2.3-3.1,4.3-5,6.2c-2.4,2.6-5,5.3-6,8.8c-0.5,1.8-0.5,3.7-0.5,5.6c0,2.2,0,4.3-0.8,6.2c-0.7,1.8-1.9,3.3-3.1,4.7 c-0.7,0.8-1.4,1.8-2,2.7c-2,3.3-3,7.3-2.4,11.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c-0.5-3.8,0.3-7.7,2.3-11 c0.5-0.9,1.4-1.8,2-2.7c1.2-1.5,2.4-3.1,3.1-4.9c0.8-2,0.8-4.2,0.8-6.4c0-1.8,0-3.7,0.5-5.4c0.9-3.4,3.4-6,6-8.7 c1.9-1.9,3.8-3.9,5-6.2c1.1-1.9,1.8-4.2,2.3-6.2c0.3-1.1,0.7-2.2,0.9-3.3c0.9-2.7,2.7-6.2,6-7.6c0.1,0,0.1-0.1,0.1-0.3 C264.9,134.2,264.8,134.2,264.8,134.2z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M212.7,253c0.1,0,0.3,0,0.4,0c2.8-1.6,6.5-2.2,10.3-1.6c3.9,0.5,7.5,2,10.2,3.4c2.3,1.1,4.6,2.3,5.7,4.5 c0.1,0.1,0.3,0.3,0.5,0.1c0.1-0.1,0.3-0.3,0.1-0.5c-1.2-2.3-3.7-3.7-6.1-4.7c-2.7-1.4-6.4-2.8-10.4-3.4c-3.9-0.5-7.9,0.1-10.9,1.8 c-0.1,0.1-0.3,0.3-0.1,0.5C212.5,252.9,212.7,252.9,212.7,253z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M239.7,259.6c-0.3,0-0.4-0.1-0.5-0.3c-1.1-2-3.4-3.3-5.6-4.3c-2.7-1.2-6.2-2.8-10.2-3.4 c-3.8-0.5-7.5,0.1-10.2,1.6c-0.1,0.1-0.4,0.1-0.5,0c-0.1,0-0.3-0.1-0.3-0.3c-0.1-0.1-0.1-0.3,0-0.4c0-0.1,0.1-0.3,0.3-0.4 c3-1.8,6.9-2.3,11-1.8c4.1,0.5,7.7,2.2,10.4,3.4c2.4,1.1,4.9,2.6,6.1,4.9c0.1,0.3,0,0.7-0.3,0.8 C239.8,259.4,239.7,259.6,239.7,259.6z M220.7,250.9c0.9,0,1.8,0,2.7,0.1c3.9,0.5,7.6,2,10.3,3.4c2.3,1.1,4.6,2.4,5.8,4.5 c0,0.1,0.1,0.1,0.3,0c0,0,0,0,0.1-0.1v-0.1c-1.2-2.2-3.5-3.5-6-4.6c-2.7-1.4-6.4-2.8-10.3-3.4c-3.9-0.5-7.7,0.1-10.7,1.8 c0,0,0,0-0.1,0.1v0.1c0,0,0,0,0.1,0h0.1C215.1,251.6,217.8,250.9,220.7,250.9z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M232.5,261.2h0.1c0.3,0,0.4-0.1,0.4-0.4c0-2.7,0.5-5.6,1.5-8.1c0.1-0.1,0-0.4-0.3-0.5c-0.1-0.1-0.4,0-0.5,0.3 c-1.1,2.6-1.6,5.6-1.6,8.4C232.3,260.9,232.3,261.1,232.5,261.2z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M232.6,261.5c-0.1,0-0.1,0-0.3,0c-0.3-0.1-0.4-0.3-0.4-0.5c0-2.8,0.5-5.8,1.6-8.4c0-0.1,0.1-0.3,0.3-0.3 c0.1,0,0.3,0,0.4,0c0.3,0.1,0.4,0.4,0.3,0.8c-0.9,2.6-1.5,5.3-1.5,8C233.3,261.2,233,261.5,232.6,261.5z M234.2,252.4 c-0.1,0-0.1,0-0.1,0.1c-1.1,2.6-1.6,5.4-1.5,8.3c0,0,0,0.1,0.1,0.1c0.1,0,0.3,0,0.3-0.1c0-2.7,0.5-5.6,1.5-8.1 C234.4,252.5,234.4,252.5,234.2,252.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M248.3,136.6c0.3,0,0.5,0,0.5-0.3c0.4-2,2-4.3,2-4.3c0.1-0.1,0.1-0.4-0.1-0.5c-0.1-0.1-0.4-0.1-0.5,0.1 c-0.1,0.1-1.8,2.4-2.2,4.6C248.1,136.3,248.2,136.6,248.3,136.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M248.5,136.9c-0.1,0-0.1,0-0.3,0c-0.3-0.1-0.4-0.4-0.3-0.7c0.4-2.2,2-4.5,2.2-4.7c0.1-0.1,0.3-0.3,0.4-0.3 c0.1,0,0.3,0,0.4,0.1c0.3,0.1,0.3,0.5,0.1,0.8c0,0.1-1.6,2.3-2,4.2C249,136.7,248.7,136.9,248.5,136.9z M248.5,136.5 c0.1,0,0.3,0,0.3-0.1c0.4-2,1.9-4.2,2-4.5c0-0.1,0-0.1,0-0.3h-0.1h-0.1c-0.1,0.3-1.8,2.4-2.2,4.6 C248.3,136.3,248.3,136.3,248.5,136.5z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M239.8,158.9c0.3,0,0.5,0,0.5-0.3c0.4-2,2-4.3,2-4.3c0.1-0.1,0.1-0.4-0.1-0.5c-0.1-0.1-0.4-0.1-0.5,0.1 c-0.1,0.1-1.8,2.4-2.2,4.6C239.5,158.7,239.7,158.9,239.8,158.9z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M239.9,159.1c-0.1,0-0.3,0-0.3,0c-0.3-0.1-0.4-0.4-0.3-0.7c0.4-2.2,2.2-4.6,2.2-4.7c0.1-0.1,0.3-0.3,0.4-0.3 c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.3,0.3,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.3-1.6,2.4-2,4.2c0,0.1-0.1,0.3-0.3,0.4 C240.2,159.1,240.1,159.1,239.9,159.1z M242.1,154C242,154,242,154,242.1,154c-0.3,0.1-1.9,2.4-2.3,4.6c0,0.1,0,0.1,0.1,0.1h0.1 c0,0,0,0,0.1-0.1c0.4-1.9,1.9-4.2,2-4.5V154C242.2,154,242.2,154,242.1,154z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M228.4,180.7c0.3,0,0.5,0,0.5-0.3c0.4-2,2-4.3,2-4.3c0.1-0.1,0.1-0.4-0.1-0.5c-0.1-0.1-0.4-0.1-0.5,0.1 c-0.1,0.1-1.8,2.4-2.2,4.6C228.1,180.6,228.3,180.7,228.4,180.7z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M228.5,181c-0.1,0-0.1,0-0.3,0c-0.3-0.1-0.4-0.4-0.3-0.7c0.4-2.2,2.2-4.6,2.2-4.7c0.1-0.1,0.3-0.3,0.4-0.3 c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.3,0.3,0.4s0,0.3-0.1,0.4c0,0-1.6,2.3-2,4.2c0,0.1-0.1,0.3-0.3,0.4 C228.7,181,228.7,181,228.5,181z M228.4,180.6c0.1,0,0.1,0,0.1,0s0,0,0.1-0.1c0.4-2,2-4.3,2-4.5c0-0.1,0-0.1,0-0.3h-0.1h-0.1 c-0.1,0.1-1.8,2.4-2.2,4.6C228.4,180.4,228.4,180.6,228.4,180.6z" }),
                            React.createElement("path", { className: "fill-primary", d: "M317.4,253.6l25.9,8.8c3.3,1.1,6.8-0.7,8-3.9l120.1-46.1c1.1-3.3-0.7-6.8-3.9-8l-120.2-38 c-3.3-1.1-6.8,0.7-8,3.9l-25.8,75.5C312.4,248.8,314.2,252.5,317.4,253.6z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M320.1,255.2c0.3,0.1,0.4,0.1,0.7,0.3l25.9,8.8c3.4,1.2,7.2-0.7,8.3-4.1l120.1-46.1c0.5-1.6,0.4-3.4-0.3-5 c-0.8-1.6-2.2-2.7-3.8-3.3l-120.2-38c-1.6-0.5-3.4-0.4-5,0.3c-1.6,0.8-2.7,2.2-3.3,3.8c0,0.1,0,0.3,0.1,0.4c0.1,0,0.3,0,0.4-0.1 c0.5-1.5,1.6-2.7,3-3.4c1.5-0.7,3.1-0.8,4.6-0.3l120.2,38c1.5,0.5,2.7,1.6,3.4,3c0.7,1.5,0.8,3.1,0.3,4.6l-120.1,46.1 c-0.5,1.5-1.6,2.7-3,3.4c-1.5,0.7-3.1,0.8-4.6,0.3l-25.9-8.8c-1.5-0.5-2.7-1.6-3.4-3c-0.7-1.5-0.8-3.1-0.3-4.6l12.6-37 c0-0.1,0-0.3-0.1-0.4c-0.1,0-0.3,0-0.4,0.1l-12.6,36.9c-0.5,1.6-0.4,3.4,0.3,5C317.7,253.5,318.8,254.5,320.1,255.2z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M348.8,264.9c-0.7,0-1.5-0.1-2.2-0.4l-25.9-8.8c-0.3-0.1-0.4-0.1-0.7-0.3c-1.4-0.7-2.4-1.8-3.3-3.1 c-0.8-1.6-0.9-3.4-0.3-5.2l12.6-36.9c0.1-0.3,0.4-0.4,0.7-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0,0.4l-12.6,36.9 c-0.5,1.5-0.4,3,0.3,4.3c0.7,1.4,1.9,2.4,3.3,2.8l25.9,8.8c1.5,0.5,3,0.4,4.3-0.3c1.4-0.7,2.4-1.9,2.8-3.3v-0.1h0.1l120-46.1 c0.4-1.5,0.4-3-0.3-4.3c-0.7-1.4-1.9-2.4-3.3-2.8l-120.2-38c-1.5-0.5-3-0.4-4.5,0.3c-1.4,0.7-2.4,1.9-2.8,3.3 c-0.1,0.3-0.4,0.4-0.7,0.3c-0.3-0.1-0.4-0.4-0.3-0.7c0.5-1.8,1.8-3.1,3.4-3.9c1.6-0.8,3.4-0.9,5.2-0.3l120.2,38 c1.8,0.5,3.1,1.8,3.9,3.4c0.8,1.6,0.9,3.4,0.3,5.2v0.1H475l-120,46.1C354.2,263.1,351.6,264.9,348.8,264.9z M329.6,210.2l-12.6,37 c-0.5,1.6-0.4,3.3,0.3,4.9c0.7,1.4,1.6,2.3,3,3c0.3,0.1,0.4,0.1,0.7,0.3l25.9,8.8c3.3,1.1,6.9-0.7,8-3.9h-0.1 c-0.5,1.5-1.6,2.7-3.1,3.5c-1.5,0.7-3.1,0.8-4.7,0.3l-25.9-8.8c-1.6-0.5-2.8-1.6-3.5-3.1s-0.8-3.1-0.3-4.7L329.6,210.2 C329.8,210.3,329.6,210.3,329.6,210.2z M348.6,167.7c0.7,0,1.4,0.1,2,0.3l120.2,38c1.6,0.5,2.8,1.6,3.5,3.1c0.7,1.5,0.8,3.1,0.3,4.7 h0.1c0.5-1.6,0.4-3.3-0.4-4.7c-0.7-1.5-2-2.7-3.7-3.3l-120.2-38c-1.6-0.5-3.3-0.4-4.9,0.3c-1.5,0.7-2.7,2-3.3,3.7v0.1h0.1 c0.5-1.6,1.6-2.8,3.1-3.5C346.9,167.8,347.7,167.7,348.6,167.7z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M436.6,290.9l-111.4,5.2l-1.4-5l58.4-89.4l112.8-0.1l5,2.8L436.6,290.9z" }),
                            React.createElement("path", { className: "fill-body", d: "M439.8,294.7L327.1,295l58.2-89.4l112.8-0.1L439.8,294.7z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M384.7,204.5l115.2-0.1l-59.7,91.5L325,295.9L384.7,204.5z M496.1,206.4l-110.3,0.1l-57,87.4l110.3-0.1 L496.1,206.4z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M290.4,299.1l153.5-0.1v-8.1l-148.2,0.1c-3,0-5.3,2.4-5.3,5.3V299.1z" }),
                            React.createElement("path", { className: "fill-mode opacity-9", d: "M419.9,250.7c-1.6,2.6-1.8,5.6-0.1,6.5c1.5,0.9,4.2-0.3,5.8-2.8c1.6-2.6,1.8-5.6,0.1-6.5 C424.1,246.8,421.5,248,419.9,250.7z" })))))),
        React.createElement("section", { className: "pt-0" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-md-10 mx-auto" },
                        React.createElement("div", { className: "accordion accordion-icon accordion-border", id: "accordionFaq" },
                            React.createElement("div", { className: "accordion accordion-icon accordion-border", id: "accordionFaq" },
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-1" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: (function () { return setButtonClicked(true); }), className: buttonClicked ? "faq-button fw-semibold rounded collapsed" : "faq-button fw-semibold rounded collapsed dark-theme", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-1", "aria-expanded": "true", "aria-controls": "collapse-1" }, "- What is Actionize?")),
                                    React.createElement("div", { id: "collapse-1", className: "accordion-collapse collapse show", "aria-labelledby": "heading-1", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3 pb-0" }, "We're a non-profit community-driven social network dedicated to fostering positive change through informed policy solutions, inspiring stories from around the globe, and direct advocacy actions."))),
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-2" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: function () { return setButtonClicked(true); }, className: "faq-button fw-semibold rounded collapsed", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-2", "aria-expanded": "false", "aria-controls": "collapse-2" }, "- How can I contribute to the community?")),
                                    React.createElement("div", { id: "collapse-2", className: "accordion-collapse collapse", "aria-labelledby": "heading-2", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3" }, "Join our new platform, and participate in discussions, share your success stories. We are a new initiative, so simply registering an account to join us means very much to our mission and our movement."))),
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-3" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: function () { return setButtonClicked(true); }, className: "faq-button fw-semibold collapsed rounded", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-3", "aria-expanded": "false", "aria-controls": "collapse-3" }, "- Is Actionize free to use?")),
                                    React.createElement("div", { id: "collapse-3", className: "accordion-collapse collapse", "aria-labelledby": "heading-3", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3" }, "Yes, our platform is free. We believe in accessible, open collaboration to drive social change. As time evolves, we aim to introduce premium accounts for a small monthly fee. This ensures a viable funding model for our platform, that relies on small individual contribution, rather than large donors or corporate interests."))),
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-4" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: function () { return setButtonClicked(true); }, className: "faq-button fw-semibold collapsed rounded", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-4", "aria-expanded": "false", "aria-controls": "collapse-4" }, "- How does Actionize ensure an ethical and user-driven environment?")),
                                    React.createElement("div", { id: "collapse-4", className: "accordion-collapse collapse", "aria-labelledby": "heading-4", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3" }, "We're built on transparency, respect, and inclusivity. Our moderation policies are community-led, focusing on constructive dialogue and meaningful action."))),
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-5" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: function () { return setButtonClicked(true); }, className: "faq-button fw-semibold collapsed rounded", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-5", "aria-expanded": "false", "aria-controls": "collapse-5" }, "- Can I find local advocacy groups on Actionize?")),
                                    React.createElement("div", { id: "collapse-5", className: "accordion-collapse collapse", "aria-labelledby": "heading-5", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3" }, "Yes! Our platform helps you connect with local groups and individuals passionate about similar causes, facilitating on-the-ground action and support. Over time, we aim to expand and include a comprehensive overview of local initiative and organisations that make a difference."))),
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-6" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: function () { return setButtonClicked(true); }, className: "faq-button fw-semibold collapsed rounded", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-6", "aria-expanded": "false", "aria-controls": "collapse-6" }, "- How can I donate to Actionize?")),
                                    React.createElement("div", { id: "collapse-6", className: "accordion-collapse collapse", "aria-labelledby": "heading-6", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3" }, "Actionize aims to rely on small individual donors, rather than grants or corporate donations, to maintain our independence and integrity. If you want to make a donation, contact us on contact@actionize.org."))),
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-7" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: function () { return setButtonClicked(true); }, className: "faq-button fw-semibold collapsed rounded", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-7", "aria-expanded": "false", "aria-controls": "collapse-7" }, "- How is the content on your website generated?")),
                                    React.createElement("div", { id: "collapse-7", className: "accordion-collapse collapse", "aria-labelledby": "heading-7", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3" }, "We rely on user generated content, as well as AI-based tools for the creation of the content of our website. On top of that, proof-reading and user feedback helps strive for maximal accuracy. If you see any content that you feel is incomplete, please use the feedback tools on our website to let us know. Together, we can strive to keep the accuracy of content at the very high standard we demand from our platform."))),
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-8" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: function () { return setButtonClicked(true); }, className: "faq-button fw-semibold collapsed rounded", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-8", "aria-expanded": "false", "aria-controls": "collapse-8" }, "- Do you have any political affiliation?")),
                                    React.createElement("div", { id: "collapse-8", className: "accordion-collapse collapse", "aria-labelledby": "heading-8", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3" }, "Actionize is completely independent from any political organisation. Our core values are diversity, respect, safety, independence and integrity."))),
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-9" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: function () { return setButtonClicked(true); }, className: "faq-button fw-semibold collapsed rounded", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-9", "aria-expanded": "false", "aria-controls": "collapse-9" }, "- What types of direct advocacy actions can I take through the platform?")),
                                    React.createElement("div", { id: "collapse-9", className: "accordion-collapse collapse", "aria-labelledby": "heading-9", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3" }, "From signing petitions to participating in organized rallies, and contacting policymakers, we strive to provide tools and resources to make your voice heard."))),
                                React.createElement("div", { className: "accordion-item mb-3" },
                                    React.createElement("div", { className: "accordion-header font-base", id: "heading-10" },
                                        React.createElement("div", { style: {
                                                borderStyle: "solid",
                                                borderColor: '#c5c0c0',
                                                borderWidth: 0.5
                                            }, onClick: function () { return setButtonClicked(true); }, className: "faq-button fw-semibold collapsed rounded", "data-bs-toggle": "collapse", "data-bs-target": "#collapse-10", "aria-expanded": "false", "aria-controls": "collapse-10" }, "- How can I stay informed about relevant policy solutions and inspirations abroad?")),
                                    React.createElement("div", { id: "collapse-10", className: "accordion-collapse collapse", "aria-labelledby": "heading-10", "data-bs-parent": "#accordionFaq" },
                                        React.createElement("div", { className: "accordion-body mt-3" }, "Our curated content includes case studies, policy analysis, and inspirational stories from around the world, updated regularly by our community and guest experts. Create an account or sign up for our newsletter to stay up to date!"))))))))),
        React.createElement("section", { className: "pt-0" },
            React.createElement("div", { className: "container bg-color" },
                React.createElement("div", { className: "rounded p-4 p-sm-5" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-lg-7 text-center mx-auto" },
                            React.createElement("ul", { className: "avatar-group mb-4 justify-content-center" },
                                React.createElement("li", { className: "avatar avatar-xs" },
                                    React.createElement("img", { className: "avatar-img rounded-circle", src: "/images/avatar/06.jpg", alt: "avatar" })),
                                React.createElement("li", { className: "avatar avatar" },
                                    React.createElement("img", { className: "avatar-img rounded-circle", src: "/images/avatar/05.jpg", alt: "avatar" })),
                                React.createElement("li", { className: "avatar avatar-lg z-index-2" },
                                    React.createElement("img", { className: "avatar-img rounded-circle", src: "/images/avatar/02.jpg", alt: "avatar" })),
                                React.createElement("li", { className: "avatar avatar z-index-1" },
                                    React.createElement("img", { className: "avatar-img rounded-circle", src: "/images/avatar/03.jpg", alt: "avatar" })),
                                React.createElement("li", { className: "avatar avatar-xs" },
                                    React.createElement("img", { className: "avatar-img rounded-circle", src: "/images/avatar/04.jpg", alt: "avatar" }))),
                            React.createElement("h4", null, "Still have a question?"),
                            React.createElement("p", { className: "mb-4" }, "We'd be happy to help you with any questions you have! Please let us know what you're looking for, and we'll do our best to assist you."),
                            React.createElement(NavLink, { href: 'contact-us' },
                                React.createElement("div", { style: {
                                        borderStyle: "solid",
                                        borderColor: '#c5c0c0',
                                        borderWidth: 0.5
                                    }, className: "btn btn-lg btn-dark rounded-2 mb-0" }, "Contact Us")))))))));
};
export default FaqContent;
