function resolve(obj) {
    return obj && obj.__esModule ? obj.default : obj;
}
function generatePageOnEnter(route) {
    var pagePromise;
    route.onPageEnter = function (routerState, routerStore) {
        if (!pagePromise && route.page) {
            pagePromise = Promise.resolve(route.page())
                .then(function (module) {
                return resolve(module);
            })
                .catch(function (err) {
                throw err;
            });
        }
        pagePromise.then(function (page) {
            var _a = routerStore.options, rootStore = _a.rootStore, pageStore = _a.rootStore.pageStore;
            pageStore.showPage(new page(rootStore, routerState.params, routerState.queryParams));
        });
        return pagePromise;
    };
}
export function generateRoutes(routes) {
    var generatedRoutes = [];
    for (var _i = 0, routes_1 = routes; _i < routes_1.length; _i++) {
        var route = routes_1[_i];
        if (route.page) {
            generatePageOnEnter(route);
        }
        generatedRoutes.push(route);
    }
    return generatedRoutes;
}
