var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import '../../css/app.css';
import { observer } from "mobx-react";
import BasePage from "./BasePage";
import { PageContent } from "@components/shared/Layout";
import Footer from "@components/layout/Footer";
import Header from "@components/layout/Header";
import PrivacyPolicy from "@components/shared/PrivacyPolicy";
var PrivacyPolicyPage = /** @class */ (function (_super) {
    __extends(PrivacyPolicyPage, _super);
    function PrivacyPolicyPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Here you can use your listTableStore or formStore or any other variables
    // e.g., this.props.page.listTableStore
    PrivacyPolicyPage.prototype.render = function () {
        return (React.createElement(PageContent, null,
            React.createElement(Header, null),
            React.createElement(PrivacyPolicy, null),
            React.createElement(Footer, null)));
    };
    PrivacyPolicyPage = __decorate([
        observer
    ], PrivacyPolicyPage);
    return PrivacyPolicyPage;
}(React.Component));
export { PrivacyPolicyPage };
var PrivacyPolicyPageStore = /** @class */ (function (_super) {
    __extends(PrivacyPolicyPageStore, _super);
    function PrivacyPolicyPageStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.component = PrivacyPolicyPage;
        return _this;
    }
    // Define your store here like formStore or ListTableStore or any observable variable or methods
    // e.g, formStore: FormStore<FormData> = new FormStore(new FormData());
    // listTableStore: ListTableStore<ListItem>;
    PrivacyPolicyPageStore.prototype.load = function () {
        this.setPageTitle('Privacy Policy');
    };
    return PrivacyPolicyPageStore;
}(BasePage));
export { PrivacyPolicyPageStore };
