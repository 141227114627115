var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FullPageError = /** @class */ (function () {
    function FullPageError(message) {
        this.message = message;
    }
    return FullPageError;
}());
export { FullPageError };
var NotFoundError = /** @class */ (function (_super) {
    __extends(NotFoundError, _super);
    function NotFoundError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return NotFoundError;
}(FullPageError));
export { NotFoundError };
var UnauthorisedError = /** @class */ (function () {
    function UnauthorisedError() {
        window.location.href = "/";
    }
    return UnauthorisedError;
}());
export { UnauthorisedError };
var FlashError = /** @class */ (function () {
    function FlashError(message) {
        this.message = '';
        this.message = message;
    }
    return FlashError;
}());
export { FlashError };
var ServerError = /** @class */ (function (_super) {
    __extends(ServerError, _super);
    function ServerError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServerError;
}(FlashError));
export { ServerError };
var SubmissionError = /** @class */ (function (_super) {
    __extends(SubmissionError, _super);
    function SubmissionError(message, errors) {
        var _this = _super.call(this, message) || this;
        _this.errors = errors;
        return _this;
    }
    return SubmissionError;
}(FlashError));
export { SubmissionError };
var SubmissionErrorWithConfirm = /** @class */ (function (_super) {
    __extends(SubmissionErrorWithConfirm, _super);
    function SubmissionErrorWithConfirm(response, errors) {
        var _this = _super.call(this, response.data.message) || this;
        _this.errors = errors;
        _this.showConfirmAlert = response.data.showConfirmAlert;
        return _this;
    }
    return SubmissionErrorWithConfirm;
}(FlashError));
export { SubmissionErrorWithConfirm };
