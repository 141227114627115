var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { observer, Provider } from "mobx-react";
import { PageTitle } from "@components/layout/PageTitle";
import { RootStoreContext } from "@components/context/AppContext";
import { RouterContext } from 'mobx-state-router';
var AppContainer = /** @class */ (function (_super) {
    __extends(AppContainer, _super);
    function AppContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppContainer.prototype.render = function () {
        var rootStore = this.props.rootStore;
        var page = rootStore.pageStore.currentPage;
        if (!page) {
            return React.createElement("h1", null, "App container no page");
        }
        var pageComponent = React.createElement(page.component, {
            page: page,
            rootStore: rootStore,
        });
        return (React.createElement("div", { className: 'App' },
            React.createElement(PageTitle, { pageStore: rootStore.pageStore }),
            pageComponent));
    };
    AppContainer = __decorate([
        observer
    ], AppContainer);
    return AppContainer;
}(React.Component));
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.render = function () {
        return (React.createElement(Provider, { rootStore: this.props.rootStore },
            React.createElement(RootStoreContext.Provider, { value: this.props.rootStore },
                React.createElement(RouterContext.Provider, { value: this.props.rootStore.routerStore },
                    React.createElement(AppContainer, { rootStore: this.props.rootStore })))));
    };
    return App;
}(React.Component));
export { App };
