import React from "react";
var Plan = function (_a) {
    var title = _a.title, description = _a.description, price = _a.price;
    return (React.createElement("div", { className: 'plan' },
        React.createElement("h3", null, title),
        React.createElement("p", null, description),
        React.createElement("p", null, price && price)));
};
var Plans = function () {
    var freePlan = "Join the waiting list for our free plan";
    var paidPlan = "Get early access";
    var price = '$ 2.99 / month';
    var plans = {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        justifyContent: "center",
    };
    return (React.createElement("div", { className: 'plans-container' },
        React.createElement("h3", null, "Plans"),
        React.createElement("div", { style: plans },
            React.createElement(Plan, { title: 'Free', description: freePlan }),
            React.createElement(Plan, { title: 'Premium', description: paidPlan, price: price }))));
};
export default Plans;
