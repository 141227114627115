import * as ReactDOM from 'react-dom/client';
import React from 'react';
import { App } from "@components/App";
import { RootStore } from "@stores/RootStore";
import { routes } from "./routes";
import { browserHistory, HistoryAdapter } from "mobx-state-router";
var rootStore = new RootStore(routes, window.initialState);
var historyAdapter = new HistoryAdapter(rootStore.routerStore, browserHistory);
historyAdapter.observeRouterStateChanges();
var container = document.getElementById('react-app');
var root = ReactDOM.createRoot(container);
root.render(React.createElement(App, { rootStore: rootStore }));
