var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as React from "react";
import { observer } from "mobx-react";
import BasePage from "./BasePage";
import { PageContent } from "@components/shared/Layout";
import { makeObservable, observable } from "mobx";
import { FormStore } from "@stores/FormStore";
var ContactUsFormStoreData = /** @class */ (function () {
    function ContactUsFormStoreData() {
        this.name = null;
        this.email = null;
        this.mobileNumber = null;
        this.message = null;
    }
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], ContactUsFormStoreData.prototype, "name", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], ContactUsFormStoreData.prototype, "email", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], ContactUsFormStoreData.prototype, "mobileNumber", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], ContactUsFormStoreData.prototype, "message", void 0);
    return ContactUsFormStoreData;
}());
var ContactUsPage = /** @class */ (function (_super) {
    __extends(ContactUsPage, _super);
    function ContactUsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (e) {
            _this.props.page.formStore.setValue(e.target.name, e.target.value);
        };
        return _this;
    }
    ContactUsPage.prototype.render = function () {
        var data = this.props.page.formStore.data;
        return (React.createElement(PageContent, null,
            React.createElement("div", { className: "mx-auto col" },
                React.createElement("h3", { className: "page-heading" }, "This is ContactUs page"),
                React.createElement("form", null,
                    React.createElement("label", { htmlFor: "name" }, "Name:"),
                    React.createElement("input", { type: "text", id: "name", name: "name", value: data.name, onChange: this.handleChange, required: true }),
                    React.createElement("label", { htmlFor: "email" }, "Email:"),
                    React.createElement("input", { type: "email", id: "email", name: "email", value: data.email, onChange: this.handleChange, required: true }),
                    React.createElement("label", { htmlFor: "mobileNumber" }, "Mobile Number:"),
                    React.createElement("input", { type: "tel", id: "mobileNumber", name: "mobileNumber", value: data.mobileNumber, onChange: this.handleChange, required: true }),
                    React.createElement("label", { htmlFor: "message" }, "Message:"),
                    React.createElement("textarea", { id: "message", name: "message", value: data.message, onChange: this.handleChange, required: true }),
                    React.createElement("button", { type: "button", onClick: this.props.page.submitContactForm }, "Submit")))));
    };
    ContactUsPage = __decorate([
        observer
    ], ContactUsPage);
    return ContactUsPage;
}(React.Component));
var ContactUsPageStore = /** @class */ (function (_super) {
    __extends(ContactUsPageStore, _super);
    function ContactUsPageStore(rootStore, params, queryParams) {
        var _this = _super.call(this, rootStore, params, queryParams) || this;
        _this.component = ContactUsPage;
        _this.submitContactForm = function () {
            var formData = {
                name: _this.formStore.data.name,
                email: _this.formStore.data.email,
                mobileNumber: _this.formStore.data.mobileNumber,
                message: _this.formStore.data.message
            };
            _this.rootStore.api.Contacts.submitContact(formData).then(function (response) {
            });
        };
        makeObservable(_this);
        _this.formStore = new FormStore(new ContactUsFormStoreData());
        return _this;
    }
    ContactUsPageStore.prototype.load = function () {
        this.setPageTitle('Contacts');
    };
    return ContactUsPageStore;
}(BasePage));
export { ContactUsPageStore };
