import { PageStore } from "./PageStore";
import { createRouterState } from "mobx-state-router";
import { RouterStore } from "./RouterStore";
import { generateRoutes } from "@utils/generateRoutes";
import SessionStore from "@stores/SessionStore";
import axios from "axios";
import { Api } from "../Api";
var notFound = createRouterState('notFound');
var RootStore = /** @class */ (function () {
    function RootStore(routes, initialState) {
        if (initialState === null || initialState === void 0 ? void 0 : initialState.csrf_token) {
            axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
            axios.defaults.headers.common['X-CSRF-TOKEN'] = initialState.csrf_token;
        }
        this.api = new Api();
        this.routerStore = new RouterStore(generateRoutes(routes), notFound, { rootStore: this });
        this.sessionStore = new SessionStore(this, initialState);
        this.pageStore = new PageStore(this);
    }
    RootStore.prototype.getThis = function () {
        return this;
    };
    return RootStore;
}());
export { RootStore };
