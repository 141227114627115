var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import '../../css/app.css';
import { observer } from "mobx-react";
import BasePage from "./BasePage";
import { PageContent } from "@components/shared/Layout";
import Details from "@components/shared/Details";
import PopularCommunities from "@components/shared/PopularCommunitites";
import Newsletter from "@components/shared/Newsletter";
import { Button } from "reactstrap";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import { TbCurrencyDollarOff } from "react-icons/tb";
import Footer from "@components/layout/Footer";
import Plans from "@components/shared/Plans";
import EarlyBacker from "@components/shared/EarlyBacker";
import Testimonials from "@components/shared/Testimonials";
import DiscoverMission from "@components/shared/DiscoverMission";
import { action, makeObservable, observable } from "mobx";
import Header from "@components/layout/Header";
var DashboardPage = /** @class */ (function (_super) {
    __extends(DashboardPage, _super);
    function DashboardPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // Here you can use your listTableStore or formStore or any other variables
    // e.g., this.props.page.listTableStore
    DashboardPage.prototype.render = function () {
        var rootStore = this.props.rootStore;
        return (React.createElement(PageContent, null,
            React.createElement("section", { style: { margin: 0, padding: 0 } },
                React.createElement(Header, null),
                React.createElement("div", { className: "dashboard-container" },
                    React.createElement("div", { className: "row justify-content-xl-start justify-content-center flex-xl-nowrap " },
                        React.createElement("div", { className: " col-xl-6 ps-xl-5 mb-8 mb-xl-0" },
                            React.createElement("h1", { className: "mt-4" }, "Welcome to Actionize"),
                            React.createElement("h3", { className: 'link-color' }, "the non-profit social network for positive action"),
                            React.createElement("p", { className: "mt-4" }, "Connect. Learn. Take action"),
                            React.createElement("div", { className: "d-flex gap-3 gap-sm-4 flex-wrap mt-4 mt-md-5" },
                                React.createElement("div", { className: 'main-buttons' },
                                    !rootStore.sessionStore.isAuthenticated && React.createElement(Button, { href: "signup", className: "join-button button arrow" }, "Join"),
                                    React.createElement(Button, { className: " contact contact-arrow" }, "About Us")))),
                        React.createElement("img", { className: 'team-image', src: '/images/dashboard/team.jpg', alt: 'team' })),
                    React.createElement(PopularCommunities, null),
                    React.createElement("div", { className: 'details-container' },
                        React.createElement(Details, { title: 'Action oriented', description: 'Inspired by impactful policies worldwide,the actionize community pushes for positive change that matters', icon: React.createElement(FiPlusCircle, { size: 50, color: '#0aa073' }) }),
                        React.createElement(Details, { title: 'Non commercial', description: 'Actionize is the first non-profit social network.This means your data is not sold,and your newsfeed is not manipulated by the corporate interest groups', icon: React.createElement(TbCurrencyDollarOff, { size: 50, color: '#0b5ed7' }) }),
                        React.createElement(Details, { title: 'Community run', description: 'Actionize fully community run.All members get to vote on crucial matters', icon: React.createElement(MdOutlinePeopleAlt, { size: 50, color: '#fdb915' }) }))),
                React.createElement(DiscoverMission, null),
                React.createElement(Plans, null),
                React.createElement(EarlyBacker, null),
                React.createElement(Testimonials, null)),
            React.createElement(Newsletter, { email: this.props.page.email, subscribeNewsLetter: this.props.page.subscribeNewsLetter, setEmail: this.props.page.setEmail, isLoading: this.props.page.isLoading, errorElement: this.props.page.error, errorMessage: this.props.page.errorMessage }),
            React.createElement(Footer, null)));
    };
    DashboardPage = __decorate([
        observer
    ], DashboardPage);
    return DashboardPage;
}(React.Component));
export { DashboardPage };
var DashboardPageStore = /** @class */ (function (_super) {
    __extends(DashboardPageStore, _super);
    function DashboardPageStore(rootStore, params, queryParams) {
        var _this = _super.call(this, rootStore, params, queryParams) || this;
        _this.component = DashboardPage;
        // Define your store here like formStore or ListTableStore or any observable variable or methods
        // e.g, formStore: FormStore<FormData> = new FormStore(new FormData());
        // listTableStore: ListTableStore<ListItem>;
        _this.email = null;
        _this.isLoading = false;
        _this.error = false;
        _this.errorMessage = null;
        _this.setEmail = function (email) { return _this.email = email; };
        _this.subscribeNewsLetter = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.email) {
                    this.setError(true);
                    return [2 /*return*/];
                }
                this.setIsLoading(true);
                this.rootStore.api.NewsLetter.subscribeNewsLetter({ email: this.email })
                    .then(function () {
                    _this.setError(false);
                    alert("Email sent successfully");
                })
                    .catch(function (error) {
                    _this.setErrorMessage(error.message);
                    _this.setError(true);
                })
                    .finally(function () {
                    _this.setIsLoading(false);
                });
                return [2 /*return*/];
            });
        }); };
        makeObservable(_this);
        _this.setErrorMessage(null);
        _this.setIsLoading(false);
        _this.setError(false);
        return _this;
    }
    DashboardPageStore.prototype.load = function () {
        this.setPageTitle('Dashboard');
    };
    DashboardPageStore.prototype.setIsLoading = function (value) {
        this.isLoading = value;
    };
    DashboardPageStore.prototype.setError = function (value) {
        this.error = value;
    };
    DashboardPageStore.prototype.setErrorMessage = function (value) {
        this.errorMessage = value;
    };
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], DashboardPageStore.prototype, "email", void 0);
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], DashboardPageStore.prototype, "isLoading", void 0);
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], DashboardPageStore.prototype, "error", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], DashboardPageStore.prototype, "errorMessage", void 0);
    __decorate([
        action,
        __metadata("design:type", Object)
    ], DashboardPageStore.prototype, "setEmail", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], DashboardPageStore.prototype, "setIsLoading", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], DashboardPageStore.prototype, "setError", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardPageStore.prototype, "setErrorMessage", null);
    return DashboardPageStore;
}(BasePage));
export { DashboardPageStore };
