import React from "react";
import { Nav, NavLink } from "reactstrap";
import { observer } from "mobx-react";
import { useRootStore } from "@components/context/AppContext";
var Header = function () {
    var rootStore = useRootStore();
    return (React.createElement("div", { className: "main-navbar" },
        React.createElement(Nav, { className: "navbar navbar-expand-xl" },
            React.createElement("div", { className: "container" },
                React.createElement("a", { className: "navbar-brand me-0", href: "/" },
                    React.createElement("img", { className: 'main-logo', src: '/images/dashboard/logoActionize.jpg', alt: 'actionize-logo' })),
                React.createElement("div", { className: "navbar-collapse collapse", id: "navbarCollapse" },
                    React.createElement("ul", { className: "navbar-nav navbar-nav-scroll dropdown-hover mx-auto" },
                        React.createElement("li", { className: "nav-item" },
                            React.createElement(NavLink, { className: "demos-link", href: "/" }, "Home")),
                        React.createElement("li", { className: "nav-item" },
                            React.createElement(NavLink, { className: "demos-link", href: "contact-us" }, "Contact us")),
                        React.createElement("li", { className: "nav-item" },
                            " ",
                            React.createElement(NavLink, { className: "demos-link", href: "/" }, "About Us")))),
                React.createElement("ul", { className: "nav align-items-center dropdown-hover ms-sm-2" },
                    !rootStore.sessionStore.isAuthenticated && React.createElement("li", { className: "nav-item me-2" },
                        React.createElement(NavLink, { href: "/signup", className: "btn btn-sm btn-light mb-0 link-color" },
                            React.createElement("i", { className: "bi bi-person-circle me-1 link-color" }),
                            "Sign up")),
                    rootStore.sessionStore.isAuthenticated && React.createElement("li", { className: "navbar-nav nav-item demos-link nav-link", onClick: rootStore.sessionStore.logoutUser }, " Logout"),
                    React.createElement("li", { className: "nav-item" },
                        React.createElement("button", { className: "navbar-toggler ms-sm-3 p-2", type: "button", "data-bs-toggle": "collapse", "data-bs-target": "#navbarCollapse", "aria-controls": "navbarCollapse", "aria-expanded": "false", "aria-label": "Toggle navigation" },
                            React.createElement("span", { className: "navbar-toggler-animation" },
                                React.createElement("span", null),
                                React.createElement("span", null),
                                React.createElement("span", null)))))))));
};
export default observer(Header);
