var BasePage = /** @class */ (function () {
    function BasePage(rootStore, params, queryParams) {
        this.rootStore = rootStore;
        this.params = params;
        this.queryParams = queryParams;
    }
    BasePage.prototype.setPageTitle = function (title) {
        this.rootStore.pageStore.setPageTitle(title);
    };
    return BasePage;
}());
export default BasePage;
