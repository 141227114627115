var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import BasePage from "./BasePage";
import { PageContent } from "@components/shared/Layout";
var HomeTestPage = /** @class */ (function (_super) {
    __extends(HomeTestPage, _super);
    function HomeTestPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function () {
            _this.props.rootStore.routerStore.goTo('Details', {
                params: { id: 'electronics' },
            });
        };
        return _this;
    }
    HomeTestPage.prototype.render = function () {
        return (React.createElement(PageContent, null,
            React.createElement("div", { className: "mx-auto col" },
                React.createElement("h3", { className: "page-heading" }, "This is home test page"),
                React.createElement("button", { onClick: this.handleClick }, "Go to Electronics"))));
    };
    return HomeTestPage;
}(React.Component));
export { HomeTestPage };
var HomeTestPageStore = /** @class */ (function (_super) {
    __extends(HomeTestPageStore, _super);
    function HomeTestPageStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.component = HomeTestPage;
        return _this;
    }
    HomeTestPageStore.prototype.load = function () {
        this.setPageTitle('Home Test Page');
    };
    return HomeTestPageStore;
}(BasePage));
export { HomeTestPageStore };
