import * as React from "react";
import { useRootStore } from "@components/context/AppContext";
var PrivacyPolicy = function () {
    var rootStore = useRootStore();
    return (React.createElement(React.Fragment, null,
        React.createElement("section", null,
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-xl-8 mx-auto" },
                        React.createElement("div", { className: "text-center mb-6" },
                            React.createElement("h1", { style: { fontSize: 50, fontWeight: 'bold' } }, "Privacy Policy"),
                            React.createElement("p", { className: "lead mb-0" }, "Last update on Dec 2023")),
                        React.createElement("div", { className: 'privacy-policy' },
                            React.createElement("h5", null, "Welcome"),
                            React.createElement("p", null, "The Actionize website is a non-profit initiative. It is founded on a simple, but powerful principle: we can do more together than any of us can do alone.\u00A0"),
                            React.createElement("p", null,
                                "We believe that information-gathering and use should go hand-in-hand with transparency. This Privacy Policy explains how Actionize.org, collects, uses, and shares information we receive from you through your use of the Actionize.org.\u00A0",
                                React.createElement("br", null)),
                            React.createElement("p", null, "We believe that you shouldn't have to provide nonpublic Personal Information to participate in the free knowledge movement. You do not have to provide things like your real name, address, or date of birth to sign up for a standard account or contribute content to the Actionize pages. While we allow for social login methods (through Facebook, Google and/or others) you can create an account using an email address and password only, allowing you to stay anonymous if you wish.\u00A0"),
                            React.createElement("p", null, "We do not sell or rent your Personal Information, nor do we give it to others to sell you anything."),
                            React.createElement("h5", null, "Types of data we receive"),
                            React.createElement("p", null,
                                React.createElement("span", { style: { color: "#202122" } }, "When you make a contribution to any Actionize page, including on user or discussion pages, you are creating a permanent, public record of every piece of content added, removed, or altered by you. The page history will show when your contribution or deletion was made, as well as your username"),
                                React.createElement("span", { style: { color: "#202122" } }, ". This username can be chosen freely, and we recommend you do not use your actual name or any other identifying information for your username. The username can also be changed whenever you want, under your account settings.\u00A0"),
                                React.createElement("br", null)),
                            React.createElement("p", null, "Want to create an account? Great! Don't want to create an account? No problem!\u00A0You are not required to create an account to read an Actionize page."),
                            React.createElement("p", null, "If you want to create a standard account, in most cases we require only an email address and a password. If you login through social media (Facebook, Google, or others), we receive your first and last name, and your email address. We will however not make this information public, and store it for internal reference only, so you can log in with the same account later on. Your actual name will not be used as your default user name.\u00A0"),
                            React.createElement("p", null,
                                "If you choose not to provide a correct email address, we can't help you recover your password.\u00A0Finally, when you visit any Actionize page, we automatically receive the IP address of the device (or your proxy server) you are using to access the Internet, which could be used to infer your geographical location",
                                React.createElement("br", null)),
                            React.createElement("h5", null, "Updates to the privacy policy"),
                            React.createElement("p", null, "This privacy policy may be updated. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page."),
                            React.createElement("h5", null, "Why We Collect Your Data"),
                            React.createElement("p", null, "We are collecting your data for several reasons:\u00A0To allow you to log in and post content, to protect the site from unwanted or inappropriate content, to better understand your needs, to send you emails containing the information we think you will find interesting, or\u00A0to customize our website according to your online behavior and personal preferences."),
                            React.createElement("h5", null, "Emails"),
                            React.createElement("p", null, "If you do not wish to receive emails, you can simply and instantly update your email preferences under your account settings."),
                            React.createElement("h5", null, "Safeguarding and Securing the Data"),
                            React.createElement("p", null, "Actionize is committed to securing your data and keeping it confidential. [name] has done all in its power to prevent data theft, unauthorized access, and disclosure by implementing the latest technologies and software, which help us safeguard all the information we collect online."),
                            React.createElement("h5", null, "Our Cookie Policy"),
                            React.createElement("p", null, "Our site uses essential cookies, which allow you to log in. We do not pass your data to other parties (so we do not allow other parties to analyze the web traffic, web pages you spend the most time on, and websites you visit)."),
                            React.createElement("p", null,
                                "If you want to disable cookies, you can do it by accessing the settings of your internet browser. (Provide links for cookie settings for major internet browsers).",
                                React.createElement("br", null)),
                            React.createElement("h5", null, "Links to Other Websites"),
                            React.createElement("p", null, "Our website contains links that lead to other websites. If you click on these links Actionize is not held responsible for your data and privacy protection. Visiting those websites is not governed by this privacy policy agreement. Make sure to read the privacy policy documentation of the website you go to from our website."),
                            React.createElement("h5", null, "Requesting Data deletion"),
                            React.createElement("p", null, "You may delete your account information at any time by letting us know on contact@Actionize.org.\u00A0"),
                            React.createElement("p", null, "When you delete your account, your profile is no longer visible to other users.\u00A0Actionize will delete your email address, username, and the content you post on the platform, both publicly and internally.\u00A0Your profile will no longer be visible, we will delete all the login and personal data we have regarding your account, and all the content or posts you have made on the platform.\u00A0You may also, at any time, request an overview of all the information we have regarding your account or user contributions.")))))),
        !rootStore.sessionStore.isAuthenticated &&
            React.createElement("section", { className: "pt-0" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "bg-dark rounded p-4 p-sm-6", "data-bs-theme": "dark" },
                        React.createElement("div", { className: "row align-items-center" },
                            React.createElement("div", { className: "col-md-6" },
                                React.createElement("h2", { style: {
                                        textAlign: 'left',
                                        color: 'white'
                                    } }, "Join our movement"),
                                React.createElement("p", { style: { textAlign: 'left' }, className: "mb-0" }, "Help us build a citizen-powered platform dedicated to positive action")),
                            React.createElement("div", { className: "col-md-6 text-end" },
                                React.createElement("a", { href: "/signup", className: "btn btn-lg btn-primary mb-0 join-button-bottom" }, "Join"))))))));
};
export default PrivacyPolicy;
