var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import * as React from "react";
import { observer } from "mobx-react";
import BasePage from "./BasePage";
import { PageContent } from "@components/shared/Layout";
var AuthPage = /** @class */ (function (_super) {
    __extends(AuthPage, _super);
    function AuthPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AuthPage.prototype.render = function () {
        var svgStyle = {
            enableBackground: 'new 0 0 150.1 20.7',
        };
        return (React.createElement(PageContent, null,
            React.createElement("main", null,
                React.createElement("div", { className: "row g-0" },
                    React.createElement("div", { className: "col-lg-7 vh-100 d-none d-lg-block" },
                        React.createElement("div", { className: "swiper h-100", "data-swiper-options": '{\n\t\t\t\t\t"pagination":{\n\t\t\t\t\t\t"el":".swiper-pagination",\n\t\t\t\t\t\t"clickable":"true"\n\t\t\t\t\t}}' },
                            React.createElement("div", { className: "swiper-wrapper" },
                                React.createElement("div", { className: "swiper-slide" },
                                    React.createElement("div", { className: "card rounded-0 h-100", "data-bs-theme": "dark", style: {
                                            backgroundImage: 'url(/images/auth/01.jpg)',
                                            backgroundPosition: "center left",
                                            backgroundSize: "cover"
                                        } },
                                        React.createElement("div", { className: "bg-overlay bg-dark opacity-5" }),
                                        React.createElement("div", { className: "card-img-overlay z-index-2 p-7" },
                                            React.createElement("div", { className: "d-flex flex-column justify-content-end h-100" },
                                                React.createElement("h4", { className: "fw-light" }, "\"With unwavering determination, they navigate the complexities of the industry, forging strategic partnerships and driving transformative change.\""),
                                                React.createElement("div", { className: "d-flex justify-content-between mt-5" },
                                                    React.createElement("div", null,
                                                        React.createElement("h5", { className: "mb-0" }, "Emma Watson"),
                                                        React.createElement("span", null, "Founder, catalog")),
                                                    React.createElement("ul", { className: "list-inline mb-1" },
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small" },
                                                            React.createElement("i", { className: "fa-solid fa-star-half-alt text-white" })))))))),
                                React.createElement("div", { className: "swiper-slide" },
                                    React.createElement("div", { className: "card rounded-0 h-100", "data-bs-theme": "dark", style: {
                                            backgroundImage: "url(/images/auth/02.jpg)",
                                            backgroundPosition: "center left",
                                            backgroundSize: "cover"
                                        } },
                                        React.createElement("div", { className: "bg-overlay bg-dark opacity-5" }),
                                        React.createElement("div", { className: "card-img-overlay z-index-2 p-7" },
                                            React.createElement("div", { className: "d-flex flex-column justify-content-end h-100" },
                                                React.createElement("h4", { className: "fw-light" }, "\"An exceptional agency CEO is a visionary, constantly pushing the boundaries of creativity and pushing their team to new heights. They inspire with their passion and cultivate a culture of trust and respect.\""),
                                                React.createElement("div", { className: "d-flex justify-content-between mt-5" },
                                                    React.createElement("div", null,
                                                        React.createElement("h5", { className: "mb-0" }, "Carolyn Ortiz"),
                                                        React.createElement("span", null, "CEO, mizzle")),
                                                    React.createElement("ul", { className: "list-inline mb-1" },
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small" },
                                                            React.createElement("i", { className: "fa-solid fa-star-half-alt text-white" })))))))),
                                React.createElement("div", { className: "swiper-slide" },
                                    React.createElement("div", { className: "card rounded-0 h-100", "data-bs-theme": "dark", style: {
                                            backgroundImage: "url(/images/auth/03.jpg)",
                                            backgroundPosition: "center left",
                                            backgroundSize: "cover"
                                        } },
                                        React.createElement("div", { className: "bg-overlay bg-dark opacity-5" }),
                                        React.createElement("div", { className: "card-img-overlay z-index-2 p-7" },
                                            React.createElement("div", { className: "d-flex flex-column justify-content-end h-100" },
                                                React.createElement("h4", { className: "fw-light" }, "\" Through collaboration and strategic direction, they steer the agency towards its goals, navigating the ever-evolving landscape with agility and grace.\""),
                                                React.createElement("div", { className: "d-flex justify-content-between mt-5" },
                                                    React.createElement("div", null,
                                                        React.createElement("h5", { className: "mb-0" }, "Dennis Barrett"),
                                                        React.createElement("span", null, "Founder, catalog")),
                                                    React.createElement("ul", { className: "list-inline mb-1" },
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small me-0" },
                                                            React.createElement("i", { className: "fa-solid fa-star text-white" })),
                                                        React.createElement("li", { className: "list-inline-item small" },
                                                            React.createElement("i", { className: "fa-solid fa-star-half-alt text-white" }))))))))),
                            React.createElement("div", { className: "swiper-pagination swiper-pagination-line mb-3" }))),
                    React.createElement("div", { className: "col-sm-10 col-lg-5 d-flex m-auto vh-100" },
                        React.createElement("div", { className: "row w-100 m-auto" },
                            React.createElement("div", { className: "col-lg-10 my-5 m-auto" },
                                React.createElement("a", { href: "index.html" },
                                    React.createElement("img", { src: "/images/logo-icon.svg", className: "h-50px mb-4", alt: "logo" })),
                                React.createElement("h2", { className: "mb-0" },
                                    "Create your",
                                    React.createElement("span", { className: "position-relative" },
                                        " account",
                                        React.createElement("span", { className: "position-absolute top-50 start-50 translate-middle z-index-n1 ms-n2 mt-2 d-none d-sm-block" },
                                            React.createElement("svg", { width: "150.1px", height: "20.7px", viewBox: "0 0 150.1 20.7", style: svgStyle, xmlSpace: "preserve" },
                                                React.createElement("path", { className: "fill-primary", d: "M10.7,12.2c-0.8,0.2-1.7,0.4-2.3,1.1C9.3,13,10.1,12.9,10.7,12.2 M63.6,1.9c3.3,0.3,6.7,0.1,10,0.2 c4.8,0.1,9.6,0.2,14.4,0.7c2.9,0.3,5.9,0.3,8.8,0.8c6.9,1,13.7,1.8,20.6,3.1c5.5,1.1,11,2.1,16.4,3.3c4.8,1.1,9.5,2.6,14.3,4 c0.7,0.2,1.3,0.5,1.7,1c-0.3,0.6-0.8-0.2-1.1,0.3c0.3,0.4,1.6,0.2,1.2,1c-0.3,0.6-1.2,1.1-2.2,1c-1.4-0.2-2.6-1-4-1.3 c-6.1-1.4-12.2-3-18.4-4c-3.8-0.6-7.6-1.4-11.5-1.7c-2.1-0.2-4.1-1-6.3-0.9c-0.5,0-1-0.3-1.6,0.2c-0.2,0.2-1,0.5-1.1-0.5 c0-0.2-0.4-0.1-0.6-0.2c-2.5-0.2-5-0.8-7.5-0.7c-2.4,0.1-4.8-0.3-7.2-0.3c-1.7,0-3.3-0.8-5.1-0.7c-0.7,0-1.5-0.1-2.2,0.2 c-0.3,0-0.5-0.1-0.8-0.1c-1.8-0.3-3.7-0.5-5.5-0.2c-1.9-0.4-3.9-0.4-5.8-0.1C68.1,7,66.1,6.8,64,7.4c-0.9,0.3-1.8,0.1-2.8,0.2 c-3.1,0.3-6.3,0.6-9.4,0.8c-0.6,0-1.2,0.3-1.8-0.2c-1.6-0.2-3.2,0-4.8,0.5c-1.6,0.5-3.2,0.4-4.8,0.5c-2.1,0.2-4.1,0.4-6,1.2 c-1.6,0.7-3.5,0.5-5.2,0.9c-3.8,0.9-7.7,1.6-11.2,3.2c-3.8,1.7-8,2.4-11.7,4.4c-0.9,0.5-1.7,1.3-2.8,1.6c-1.1,0.3-2.8-0.3-3.4-1.5 c-0.5-1-0.3-2.1,0.6-2.9c1.7-1.4,3.5-2.5,5.4-3.5c8.2-4.3,16.9-7,25.9-9c8.8-1.9,17.7-3,26.7-3.5C63.5-0.1,68.1,0,72.6,0 c4.7,0,9.4,0.1,14.1,0.5c4.2,0.4,8.3,0.9,12.5,1.4c4.9,0.6,9.7,1.3,14.5,2.1c11.6,2.1,23.1,4.4,34.2,8.4c0.7,0.3,1.7,0.1,2.2,1.1 c-0.9,0.4-1.7,0.1-2.4-0.1c-5.7-2-11.6-3.5-17.4-4.9c-8.7-2.1-17.5-3.3-26.3-4.7c-4.2-0.7-8.6-0.9-12.8-1.5 c-5.6-0.7-11.3-0.9-16.9-1.1c-3.4-0.1-6.8-0.1-10.1,0.3C63.9,1.6,63.7,1.7,63.6,1.9c-0.6-0.5-1.2-0.2-1.9-0.2 C56.9,1.9,52,2.3,47.1,2.8C44.1,3.1,41.1,3.7,38,4c-3.2,0.4-6.4,1.2-9.5,2.1c-0.9,0.2-2.1,0-2.7,1.1c-1.4-0.5-2.5,0.4-3.6,1 c1.2-0.2,2.5-0.4,3.6-1c0.3,0,0.7,0,1-0.1c9.1-2.3,18.4-3.7,27.7-4.4C57.6,2.4,60.6,2.2,63.6,1.9" }))))),
                                React.createElement("div", { className: "row mt-5" },
                                    React.createElement("div", { className: "col-xxl-6 d-grid" },
                                        React.createElement("a", { href: "#", className: "btn border bg-light mb-2 mb-xxl-0" },
                                            React.createElement("i", { className: "fab fa-fw fa-google text-google-icon me-2" }),
                                            "Signup with Google")),
                                    React.createElement("div", { className: "col-xxl-6 d-grid" },
                                        React.createElement("a", { href: "#", className: "btn border bg-light mb-0" },
                                            React.createElement("i", { className: "fab fa-fw fa-facebook-f text-facebook me-2" }),
                                            "Signup with Facebook")),
                                    React.createElement("div", { className: "position-relative my-5" },
                                        React.createElement("hr", null),
                                        React.createElement("p", { className: "small position-absolute top-50 start-50 translate-middle bg-body px-4" }, "Or"))),
                                React.createElement("form", null,
                                    React.createElement("div", { className: "input-floating-label form-floating mb-4" },
                                        React.createElement("input", { type: "email", className: "form-control", id: "floatingInput", placeholder: "name@example.com" }),
                                        React.createElement("label", { htmlFor: "floatingInput" }, "Email address")),
                                    React.createElement("div", { className: "align-items-center mt-0" },
                                        React.createElement("div", { className: "d-grid" },
                                            React.createElement("button", { className: "btn btn-dark mb-0", type: "button" }, "Send Magic Link")))),
                                React.createElement("div", { className: "mt-4 text-center" },
                                    React.createElement("span", null,
                                        "Already have an account?",
                                        React.createElement("a", { href: "sign-in.html", className: "text-decoration-none" }, " Sign in here"))))))))));
    };
    AuthPage = __decorate([
        observer
    ], AuthPage);
    return AuthPage;
}(React.Component));
export { AuthPage };
var AuthPageStore = /** @class */ (function (_super) {
    __extends(AuthPageStore, _super);
    function AuthPageStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.component = AuthPage;
        return _this;
    }
    AuthPageStore.prototype.load = function () {
        this.setPageTitle('Sign Up');
    };
    return AuthPageStore;
}(BasePage));
export { AuthPageStore };
