var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from "react";
import BasePage from "./BasePage";
import { PageContent } from "../components/shared/Layout";
var DetailsPage = /** @class */ (function (_super) {
    __extends(DetailsPage, _super);
    function DetailsPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DetailsPage.prototype.render = function () {
        return (React.createElement(PageContent, null,
            React.createElement("div", { className: "mx-auto col" },
                React.createElement("h3", { className: "page-heading" }, "This is Details page"))));
    };
    return DetailsPage;
}(React.Component));
var DetailsPageStore = /** @class */ (function (_super) {
    __extends(DetailsPageStore, _super);
    function DetailsPageStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.component = DetailsPage;
        return _this;
    }
    DetailsPageStore.prototype.load = function () {
        this.setPageTitle('Details Page');
    };
    return DetailsPageStore;
}(BasePage));
export { DetailsPageStore };
