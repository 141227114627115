var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import React from 'react';
import BasePage from "./BasePage";
import { PageContent } from "@components/shared/Layout";
import Footer from "@components/layout/Footer";
import Header from "@components/layout/Header";
import { NavLink } from "reactstrap";
import { observer } from "mobx-react";
import { FormStore } from "@stores/FormStore";
import { action, makeObservable, observable } from "mobx";
var ContactUsFormStoreData = /** @class */ (function () {
    function ContactUsFormStoreData() {
        this.name = "";
        this.email = "";
        this.mobileNumber = "";
        this.message = "";
    }
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ContactUsFormStoreData.prototype, "name", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ContactUsFormStoreData.prototype, "email", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ContactUsFormStoreData.prototype, "mobileNumber", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], ContactUsFormStoreData.prototype, "message", void 0);
    return ContactUsFormStoreData;
}());
var ContactUsPage = /** @class */ (function (_super) {
    __extends(ContactUsPage, _super);
    function ContactUsPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (e) {
            _this.props.page.formStore.setValue(e.target.name, e.target.value);
        };
        _this.handleSubmit = function () {
            _this.props.page.submitContactForm();
        };
        return _this;
    }
    ContactUsPage.prototype.render = function () {
        var isLoading = this.props.page.isLoading;
        return (React.createElement(PageContent, null,
            React.createElement(Header, null),
            React.createElement("section", { className: "pt-xl-8" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row g-4 g-xxl-5" },
                        React.createElement("div", { className: "col-xl-9 mx-auto" },
                            React.createElement("img", { src: "/images/Contact/02.jpg", className: "rounded", alt: "contact-bg" }),
                            React.createElement("div", { className: "row mt-n5 mt-sm-n7 mt-md-n8" },
                                React.createElement("div", { className: "col-11 col-lg-9 mx-auto" },
                                    React.createElement("div", { className: "card shadow p-4 p-sm-5 p-md-6" },
                                        React.createElement("div", { className: "card-header border-bottom px-0 pt-0 pb-5" },
                                            React.createElement("nav", { className: "mb-3", "aria-label": "breadcrumb" },
                                                React.createElement("ol", { className: "breadcrumb breadcrumb-dots pt-0" },
                                                    React.createElement("li", { className: "breadcrumb-item link-color" },
                                                        React.createElement(NavLink, { className: 'link-color', href: "/" }, "Home")),
                                                    React.createElement("li", { className: "breadcrumb-item link-color", "aria-current": "page" }, "Contact us"))),
                                            React.createElement("h1", { className: "mb-3 h3" }, "We love hearing from you"),
                                            React.createElement("p", { className: "mb-0" },
                                                "You can contact us with the form below, or on ",
                                                React.createElement("a", { className: 'link-color', href: "mailto:contact@actionize.org" }, "contact@actionize.org"))),
                                        React.createElement("form", { className: "card-body px-0 pb-0 pt-5" },
                                            React.createElement("div", { className: "input-floating-label form-floating mb-4" },
                                                React.createElement("input", { type: "text", className: "form-control bg-transparent form-input", id: "floatingName", name: "name", onChange: this.handleChange, placeholder: "Your name" }),
                                                React.createElement("label", { htmlFor: "floatingName" }, "Your name")),
                                            React.createElement("div", { className: "input-floating-label form-floating mb-4" },
                                                React.createElement("input", { type: "email", className: "form-control bg-transparent form-input", id: "floatingInput", name: "email", onChange: this.handleChange, placeholder: "name@example.com", required: true }),
                                                React.createElement("label", { htmlFor: "floatingInput" }, "Email address * ")),
                                            React.createElement("div", { className: "input-floating-label form-floating mb-4" },
                                                React.createElement("input", { type: "text", className: "form-control bg-transparent form-input", id: "floatingNumber", name: "mobileNumber", onChange: this.handleChange, placeholder: "Phone number" }),
                                                React.createElement("label", { htmlFor: "floatingNumber" }, "Phone number")),
                                            React.createElement("div", { className: "input-floating-label form-floating mb-4" },
                                                React.createElement("textarea", { className: "form-control bg-transparent form-input", placeholder: "Leave a comment here", id: "floatingTextarea2", name: "message", onChange: this.handleChange, style: { height: 100 }, required: true }),
                                                React.createElement("label", { htmlFor: "floatingTextarea2" }, "Message * ")),
                                            React.createElement("button", { className: "btn btn-lg btn-primary mb-0 common-bg", onClick: this.handleSubmit, disabled: isLoading }, isLoading ? "Loading..." : "Send a message"))))))))),
            React.createElement(Footer, null)));
    };
    ContactUsPage = __decorate([
        observer
    ], ContactUsPage);
    return ContactUsPage;
}(React.Component));
export { ContactUsPage };
var ContactUsPageStore = /** @class */ (function (_super) {
    __extends(ContactUsPageStore, _super);
    function ContactUsPageStore(rootStore, params, queryParams) {
        var _this = _super.call(this, rootStore, params, queryParams) || this;
        _this.component = ContactUsPage;
        _this.isLoading = false;
        _this.error = false;
        _this.errorMessage = null;
        _this.validateEmail = function (email) {
            var emailRegex = /^[^@\s]+@[^@\s]+$/;
            return emailRegex.test(email);
        };
        _this.submitContactForm = function () {
            var formData = {
                name: _this.formStore.data.name,
                email: _this.formStore.data.email,
                mobileNumber: _this.formStore.data.mobileNumber,
                message: _this.formStore.data.message
            };
            _this.setLoading(true);
            if (formData.email.trim() === "" || formData.message.trim() === "" || !_this.validateEmail(formData.email)) {
                _this.setError(true);
                _this.setLoading(false);
                alert("Please enter valid data");
                return;
            }
            if (!_this.error || !_this.formStore.errors) {
                _this.rootStore.api.Contacts.submitContact(formData)
                    .then(function () {
                    _this.formStore.data.name = "";
                    _this.formStore.data.email = "";
                    _this.formStore.data.mobileNumber = "";
                    _this.formStore.data.message = "";
                    alert("Your form has been submitted successfully");
                    _this.rootStore.routerStore.goTo('Dashboard');
                    _this.setError(false);
                })
                    .catch(function (error) {
                    _this.setErrorMessage(error.message);
                    alert(error.message);
                    _this.setError(true);
                })
                    .finally(function () {
                    _this.setLoading(false);
                });
            }
        };
        makeObservable(_this);
        _this.formStore = new FormStore(new ContactUsFormStoreData());
        _this.setLoading(false);
        _this.setErrorMessage(null);
        _this.setError(false);
        return _this;
    }
    ContactUsPageStore.prototype.load = function () {
        this.setPageTitle('Contacts');
    };
    ContactUsPageStore.prototype.setLoading = function (value) {
        this.isLoading = value;
    };
    ContactUsPageStore.prototype.setError = function (value) {
        this.error = value;
    };
    ContactUsPageStore.prototype.setErrorMessage = function (value) {
        this.errorMessage = value;
    };
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], ContactUsPageStore.prototype, "isLoading", void 0);
    __decorate([
        observable,
        __metadata("design:type", Boolean)
    ], ContactUsPageStore.prototype, "error", void 0);
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], ContactUsPageStore.prototype, "errorMessage", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], ContactUsPageStore.prototype, "setLoading", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], ContactUsPageStore.prototype, "setError", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], ContactUsPageStore.prototype, "setErrorMessage", null);
    return ContactUsPageStore;
}(BasePage));
export { ContactUsPageStore };
