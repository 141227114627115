var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import BasePage from "./BasePage";
import FaqContent from "@components/shared/FaqContent";
import { PageContent } from "@components/shared/Layout";
import Footer from "@components/layout/Footer";
import Header from "@components/layout/Header";
var FaqPage = /** @class */ (function (_super) {
    __extends(FaqPage, _super);
    function FaqPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FaqPage.prototype.render = function () {
        return (React.createElement(PageContent, null,
            React.createElement(Header, null),
            React.createElement(FaqContent, null),
            React.createElement(Footer, null)));
    };
    return FaqPage;
}(React.Component));
export { FaqPage };
var FaqPageStore = /** @class */ (function (_super) {
    __extends(FaqPageStore, _super);
    function FaqPageStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.component = FaqPage;
        return _this;
    }
    FaqPageStore.prototype.load = function () {
        this.setPageTitle('FAQs');
    };
    return FaqPageStore;
}(BasePage));
export { FaqPageStore };
