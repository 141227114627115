var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable, reaction } from "mobx";
import { routeMiddleware } from "@utils/middleware";
var PageStore = /** @class */ (function () {
    function PageStore(rootStore) {
        var _this = this;
        this.currentPage = null;
        this.pageTitle = '';
        this.observeRouterStateChanges = function () {
            reaction(function () { return _this.rootStore.routerStore.routerState; }, function (routerState) {
                var route = _this.rootStore.routerStore.getRoute(routerState.routeName);
                if (route === null || route === void 0 ? void 0 : route.middleware) {
                    route.middleware
                        .reduce(function (promise, hook) {
                        var hookSplit = hook.split(':');
                        var middleware = routeMiddleware[hookSplit[0]];
                        var params = [];
                        if (hookSplit[1]) {
                            params = hookSplit[1].split('|');
                        }
                        return middleware
                            ? promise.then(function () { return middleware(routerState, _this.rootStore.routerStore, params); })
                            : promise;
                    }, Promise.resolve())
                        .then(function () {
                        if (route.page && route.onPageEnter) {
                            route.onPageEnter(routerState, _this.rootStore.routerStore);
                        }
                    })
                        .catch(function (error) {
                    });
                }
                else {
                    if ((route === null || route === void 0 ? void 0 : route.page) && route.onPageEnter) {
                        route.onPageEnter(routerState, _this.rootStore.routerStore);
                    }
                }
            }, {
                onError: function (e) {
                    throw new Error(e);
                },
            });
        };
        makeObservable(this);
        this.rootStore = rootStore;
        this.observeRouterStateChanges();
    }
    PageStore.prototype.showPage = function (page) {
        this.currentPage = page;
        this.currentPageLoadPromise = Promise.resolve(page.load());
    };
    PageStore.prototype.setPageTitle = function (pageTitle) {
        this.pageTitle = pageTitle;
    };
    __decorate([
        observable,
        __metadata("design:type", Object)
    ], PageStore.prototype, "currentPage", void 0);
    __decorate([
        observable,
        __metadata("design:type", String)
    ], PageStore.prototype, "pageTitle", void 0);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], PageStore.prototype, "showPage", null);
    __decorate([
        action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], PageStore.prototype, "setPageTitle", null);
    return PageStore;
}());
export { PageStore };
