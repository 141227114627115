var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { routerStateToUrl, RouterStore as NareshRouterStore } from 'mobx-state-router';
import { action, makeObservable } from "mobx";
function deepCopy(from, to) {
    for (var _i = 0, _a = Object.keys(to); _i < _a.length; _i++) {
        var key = _a[_i];
        delete to[key];
    }
    for (var _b = 0, _c = Object.keys(from); _b < _c.length; _b++) {
        var key = _c[_b];
        var valueFrom = from[key];
        var valueTo = to[key];
        to[key] =
            typeof valueFrom === 'object'
                ? typeof valueTo === 'object'
                    ? deepCopy(to[key], valueFrom)
                    : deepCopy({}, valueFrom)
                : valueFrom;
    }
}
function toStringValues(obj) {
    if (!obj) {
        return obj;
    }
    var toObj = {};
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        toObj[key] = typeof value === 'object' ? toStringValues(value) : "" + value;
    }
    return toObj;
}
var RouterStore = /** @class */ (function (_super) {
    __extends(RouterStore, _super);
    function RouterStore(routes, notFoundState, options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, routes, notFoundState, options) || this;
        makeObservable(_this, {
            pushQueryParams: action
        });
        return _this;
    }
    RouterStore.prototype.pushQueryParams = function (queryParams) {
        deepCopy(queryParams, this.routerState.queryParams);
        // TODO Remove global dependency
        // history.push(routerStateToUrl(this, this.routerState));
        routerStateToUrl(this, this.routerState);
    };
    // Overwrite parent class to make sure params and queryParams have only string values.
    // The RouterState constructed from location.href only contains string values, so if we don't have
    // string values in our RouterState then the router will think that both router states are different,
    // hence it will route twice when going to the given RouterState,
    // once for initial redirect and once as a result from changing the url in the browser (via history.listen).
    // TODO: look into it
    RouterStore.prototype.goTo = function (toStateOrRouteName, params, queryParams) {
        if (params === void 0) { params = {}; }
        if (queryParams === void 0) { queryParams = {}; }
        var combinedOptions = { params: toStringValues(params), queryParams: toStringValues(queryParams) };
        if (typeof toStateOrRouteName === 'string') {
            return _super.prototype.goTo.call(this, toStateOrRouteName, combinedOptions);
        }
        return _super.prototype.goTo.call(this, toStateOrRouteName.routeName, combinedOptions);
    };
    return RouterStore;
}(NareshRouterStore));
export { RouterStore };
