import React from "react";
var Details = function (_a) {
    var title = _a.title, description = _a.description, icon = _a.icon;
    return (React.createElement("div", { className: "col details" },
        React.createElement("div", { className: "p-sm-5" },
            React.createElement("div", { className: "mb-4" }, icon),
            React.createElement("h6", { className: "mb-3" }, title),
            React.createElement("p", null, description))));
};
export default Details;
