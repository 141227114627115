var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import { SubmissionError, SubmissionErrorWithConfirm, UnauthorisedError } from "@utils/errors";
var qs = require('qs');
function handleError(error) {
    var response = error.response;
    if (!!response && response.status === 422) {
        var errors_1 = {};
        Object.keys(response.data.errors || {}).forEach(function (key) {
            errors_1[key] = response.data.errors[key];
        });
        if (response.data.showConfirmAlert) {
            return new SubmissionErrorWithConfirm(response, errors_1);
        }
        return new SubmissionError(response.data.message, errors_1);
    }
    if (!!response && response.status === 401) {
        throw new UnauthorisedError();
    }
    return error;
}
function request(promise) {
    return new Promise(function (resolve, reject) {
        promise
            .then(function (response) {
            resolve(response.data);
        })
            .catch(function (error) {
            reject(handleError(error));
        });
    });
}
var Networking = /** @class */ (function () {
    function Networking() {
    }
    Networking.get = function (url, config) {
        return request(axios.get(url, __assign(__assign({}, config), { paramsSerializer: function (params) { return qs.stringify(params, { arrayFormat: 'brackets' }); } })));
    };
    Networking.post = function (url, config) {
        return request(axios.post(url, config));
    };
    Networking.delete = function (url, config) {
        return request(axios.delete(url, config));
    };
    return Networking;
}());
export { Networking };
