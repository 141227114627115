import React from "react";
import LinkBlockComponent from "@components/shared/LinkComponent";
import { observer } from "mobx-react";
import { useRootStore } from "@components/context/AppContext";
var Footer = function () {
    var quickLinksTitle = ['About us', 'Contact us', 'Sign in', 'Sign up'];
    var quickLinks = ['/', 'contact-us', 'signin', 'signup'];
    var communityLinksTitle = ['Mission', 'Faqs', 'Privacy Policy', 'Terms & Conditions'];
    var communityLinks = ['/', 'faqs', 'privacy-policy', '/'];
    var rootStore = useRootStore();
    if (rootStore.sessionStore.isAuthenticated) {
        quickLinksTitle = ['About us', 'Contact us'];
        quickLinks = ['/', 'contact-us'];
        communityLinksTitle = ['Mission', 'Faqs', 'Privacy Policy', 'Terms & Conditions'];
        communityLinks = ['/', 'faqs', 'privacy-policy', '/'];
    }
    return (React.createElement("footer", { className: "bg-dark position-relative overflow-hidden footer-elements mt-0", "data-bs-theme": "dark" },
        React.createElement("div", { style: {
                paddingBottom: 80
            }, className: "container position-relative mt-5" },
            React.createElement("div", { className: "row g-4 justify-content-between" },
                React.createElement("div", { style: {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }, className: "col-lg-3" },
                    React.createElement("a", { className: "navbar-brand me-0", href: "/" },
                        React.createElement("img", { className: 'main-logo', src: "/images/dashboard/logoActionizeDark.jpg", alt: 'actionize-logo' })),
                    React.createElement("p", { className: "mt-4 mb-2" }, "Stay informed about Actionize - join our newsletter")),
                React.createElement(LinkBlockComponent, { heading: 'Quick Links', links: quickLinks, linksTitle: quickLinksTitle }),
                React.createElement(LinkBlockComponent, { heading: 'Community', links: communityLinks, linksTitle: communityLinksTitle })))));
};
export default observer(Footer);
