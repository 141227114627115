import '../../../css/app.css';
import React from "react";
import { NavLink } from "reactstrap";
var LinkBlockComponent = function (_a) {
    var heading = _a.heading, links = _a.links, linksTitle = _a.linksTitle;
    return (React.createElement("div", { className: "col-6 col-md-4" },
        React.createElement("h6", { className: "mb-2 mb-md-4" }, heading),
        React.createElement("ul", { className: "nav flex-column" }, links.map(function (item, index) { return (React.createElement("li", { className: 'nav-item', key: index },
            React.createElement(NavLink, { href: item, className: 'demos-link nav-link contact-links' }, linksTitle[index]))); }))));
};
export default LinkBlockComponent;
