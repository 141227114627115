import React from "react";
import { NavLink } from "reactstrap";
var Testimonials = function () {
    return (React.createElement("div", { className: 'grid-container' },
        React.createElement("img", { className: 'testimonials-image', src: '/images/dashboard/group.jpg', alt: 'group-image' }),
        React.createElement("div", { className: 'testimonials-container' },
            React.createElement("h3", { className: "fw-bold text-uppercase link-color" }, "Testimonials"),
            React.createElement("h2", { className: "my-3" }, "Don't take our word for it. See what our client says."),
            React.createElement("p", { className: "mb-4" }, "Hear what our satisfied clients have to say about their experiences with our platform. Discover how we've helped agencies like yours achieve remarkable results."),
            React.createElement("div", { className: 'testimonials' },
                React.createElement("div", null,
                    React.createElement("p", null, "Democracy is too precious to leave it to politicians")),
                React.createElement("div", null,
                    React.createElement("p", null, "For the price of a cup of coffee per month I now rest assured that my data is not in the hands of corporate interest groups"))),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement("button", { type: "button", className: "btn btn-lg btn-dark rounded-2 mb-0" }, "Get Started"),
                React.createElement("div", { style: { marginLeft: 14, display: "flex", justifyContent: 'center', alignItems: "center" } },
                    React.createElement(NavLink, { style: { marginLeft: 2, textDecoration: 'none' }, className: "our-mission-arrow", href: "#" }, "Browse features "))))));
};
export default Testimonials;
