import React from "react";
import { Button } from "reactstrap";
var EarlyBacker = function () {
    return (React.createElement("div", { className: 'early-backer' },
        React.createElement("h4", null, "Become an early backer!"),
        React.createElement("div", null,
            React.createElement("p", null, "Actionize is new ,fresh and needs your help! Become an early backer - with early access, a thank you mention on your blog and more")),
        React.createElement(Button, { className: 'early-backer-button' }, "Become an angel backer")));
};
export default EarlyBacker;
