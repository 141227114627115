import { Networking } from "@utils/networking";
var NewsLetterApi = /** @class */ (function () {
    function NewsLetterApi() {
    }
    NewsLetterApi.prototype.subscribeNewsLetter = function (formData) {
        return Networking.post("api/news-letter/subscribe", formData);
    };
    return NewsLetterApi;
}());
var ContactsApi = /** @class */ (function () {
    function ContactsApi() {
    }
    ContactsApi.prototype.submitContact = function (formData) {
        return Networking.post("api/contact/submit", formData);
    };
    return ContactsApi;
}());
var UserAuthApi = /** @class */ (function () {
    function UserAuthApi() {
    }
    UserAuthApi.prototype.checkAuthentication = function () {
        return Networking.get('api/check-authentication', {});
    };
    UserAuthApi.prototype.logout = function () {
        return Networking.post('api/logout', {});
    };
    return UserAuthApi;
}());
var Api = /** @class */ (function () {
    function Api() {
        this.NewsLetter = new NewsLetterApi();
        this.Contacts = new ContactsApi();
        this.UserAuth = new UserAuthApi();
    }
    return Api;
}());
export { Api };
