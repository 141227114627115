import React from "react";
import { Button } from "reactstrap";
import GreyButton from "@components/shared/Buttons";
var PopularCommunities = function () {
    return (React.createElement("div", { className: 'communities' },
        React.createElement("h3", null, "Popular Communities"),
        React.createElement("div", { className: 'community-buttons' },
            React.createElement("div", null,
                React.createElement(GreyButton, { text: 'healthcare' }),
                React.createElement(GreyButton, { text: 'affordable housing' })),
            React.createElement("div", null,
                React.createElement(GreyButton, { text: 'inequality' }),
                React.createElement(GreyButton, { text: 'cost of living' })),
            React.createElement("div", null,
                React.createElement(GreyButton, { text: "women's rights" }),
                React.createElement(GreyButton, { text: 'education' }))),
        React.createElement("div", null,
            React.createElement(Button, { className: "our-mission-arrow" }, "Our mission"))));
};
export default PopularCommunities;
