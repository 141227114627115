import { NotFoundPageStore } from "@pages/NotFoundPage";
import { DashboardPageStore } from "@pages/DashboardPage";
import { HomeTestPageStore } from "@pages/HomeTestPage";
import { DetailsPageStore } from "@pages/DetailsPage";
import { AuthPageStore } from "@pages/AuthPage";
import { PrivacyPolicyPageStore } from "@pages/PrivacyPolicyPage";
import { FaqPageStore } from "@pages/FaqPage";
import { ContactUsPageStore } from "@pages/ContactUsPage";
import { ContactUsPageStore as ContactUsPageStoreDemo } from "@pages/ContactUsPageStore";
export var routes = [
    {
        name: 'notFound',
        pattern: '/not-found',
        page: function () { return NotFoundPageStore; }
    },
    {
        name: 'Dashboard',
        pattern: '/',
        page: function () { return DashboardPageStore; }
    },
    {
        name: 'HomeTest',
        pattern: '/home-test',
        page: function () { return HomeTestPageStore; },
    },
    {
        name: 'Details',
        pattern: '/details',
        page: function () { return DetailsPageStore; },
    },
    {
        name: 'Auth',
        pattern: '/sign-up',
        page: function () { return AuthPageStore; }
    },
    {
        name: 'PrivacyPolicy',
        pattern: '/privacy-policy',
        page: function () { return PrivacyPolicyPageStore; }
    },
    {
        name: 'ContactUsDemo',
        pattern: '/contact-us-demo',
        page: function () { return ContactUsPageStoreDemo; }
    },
    {
        name: 'FAQs',
        pattern: '/faqs',
        page: function () { return FaqPageStore; }
    },
    {
        name: 'ContactUs',
        pattern: '/contact-us',
        page: function () { return ContactUsPageStore; }
    },
];
