import React, { useContext } from "react";
export var RootStoreContext = React.createContext({});
export function useRootStore() {
    var rootStore = useContext(RootStoreContext);
    if (rootStore === undefined) {
        /* istanbul ignore next */
        throw new Error('useRootStore must be used within a RootStoreProvider');
    }
    return rootStore;
}
