import React from "react";
import { Button, NavLink } from "reactstrap";
import { useRootStore } from "@components/context/AppContext";
var DiscoverMission = function () {
    var rootStore = useRootStore();
    return (React.createElement("div", { className: 'mission-container' },
        !rootStore.sessionStore.isAuthenticated && React.createElement(NavLink, { href: "signup", className: 'join-actionize' }, "Join Actionize"),
        React.createElement(Button, { className: "mission-arrow" }, "Discover our mission"),
        React.createElement("img", { className: 'group-image', src: '/images/dashboard/group.jpg', alt: 'group-image' })));
};
export default DiscoverMission;
